import { useCallback, useEffect } from "react";
import {
    addNewMessage,
    fetchHistoryList,
    getConversationDetail,
    reset,
    setCurrentPerson,
    setCurrentSettingLoading,
    updateMessageReceipt
} from "@/store/slice/message.ts";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    ConversationType,
    ConversationTypeValue,
    enterConversation,
    getUserInfo,
    leaveConversation
} from "@chat/zim-sdk";
import { emitter } from "@/utils";
import { resetGif } from "@/store/slice/git.ts";
// 初始化当前聊天的信息，如果是群提取群信息，如果是个人提取个人
export default function useChatInit() {
    const params = useParams();
    const { userInfo } = useAppSelector(state => state.common);
    const { conversationID, conversationType } = params;
    const dispatch = useAppDispatch();
    const getGroupDetail = async (groupID: string) => {
        console.log(groupID);
    };
    const getPersonalDetail = async (userID: string) => {
        getUserInfo(userID, true).then(res => {
            dispatch(setCurrentPerson(res.user));
        });
    };
    const getDetail = async (type: ConversationTypeValue, id: string) => {
        try {
            dispatch(setCurrentSettingLoading(true));
            if (type === ConversationType.Room) {
                await getGroupDetail(id);
            } else {
                await getPersonalDetail(id);
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setCurrentSettingLoading(false));
        }
    };
    // 获取当前登录账户在群里的个人信息
    const getPersonGroupInfo = (groupID: string, userID: string) => {
        console.log(groupID, userID);
    };
    const chatInit = useCallback(() => {
        if (conversationID && userInfo && conversationType) {
            dispatch(reset());
            // 如果是个人，获取个人信息
            // 如果是群，获取群消息
            getDetail(+conversationType, conversationID);
            if (+conversationType === ConversationType.Room) {
                getPersonGroupInfo(conversationID, userInfo.userID);
            }
            // 获取会话信息
            dispatch(getConversationDetail({ conversationID, conversationType: +conversationType }));
            // 获取历史消息
            dispatch(fetchHistoryList(conversationID));
        }
    }, [conversationID, userInfo, conversationType]);
    useEffect(() => {
        if (!conversationID) return;
        enterConversation(conversationID, {
            onMessages: (list) => {
                // 更新信息
                list.forEach(message => {
                    dispatch(addNewMessage(message));
                });
                emitter.emit("CHAT_LIST_SCROLL_BY_SHOW_BTN");
            },
            onMessageReceiptChanged(infos) {
                infos.forEach(item => {
                    dispatch(updateMessageReceipt(item));
                });
            }
        });
        return () => {
            leaveConversation(conversationID);
            dispatch(resetGif());
        };
    }, [conversationID, userInfo, conversationType]);
    return [chatInit];
}
