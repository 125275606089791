import React, { memo } from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {
    return <span role="img" { ...props }>
<svg viewBox="0 0 17 17" fill="none">
<rect x="3.40039" y="3.19141" width="11" height="11" rx="3.5" stroke="#566369"/>
<path d="M8.46484 5.74609V9.74609" stroke="#566369"/>
<path d="M7.97656 9.24609L11.9766 9.24609" stroke="#566369"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
