import { useAppSelector } from "@/store";
import {ZIMMessage} from "@chat/zim-sdk"

function useSelfMessage(data: ZIMMessage) {
    const { userInfo } = useAppSelector(state => state.common);
    // 判断是否是自己的信息
    return userInfo?.userID === data.senderUserID;
}

export default useSelfMessage;
