import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type GifItem = {
    imgUrl: string;
    tag: string
}
const initialState = {
    list: [] as GifItem[],
    paginate: {
        pageSize: 30,
        totalPage: 1,
        currentPage: 0,
        totalSize: 0,
    }
};

export const gifSlice = createSlice({
    name: "gif",
    initialState,
    reducers: {
        setGifList(state, action: PayloadAction<GifItem[]>) {
            state.list.push(...action.payload);
        },
        setPaginate(state, action: PayloadAction<typeof initialState.paginate>) {
            state.paginate = {
                ...state.paginate,
                ...action.payload
            };
        },
        resetGif() {
            return initialState;
        }
    }
});

export const {
    setGifList,
    setPaginate,
    resetGif
} = gifSlice.actions;
export default gifSlice.reducer;
