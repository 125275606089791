import { useUserInfo } from "@/hooks/useUserInfo";
import { ZIMVideoMessage, MessageReceiptStatus } from "@chat/zim-sdk";
import { Flex } from "antd";
import VoiceIcon from "@/components/icon/Voice.tsx";
import { formattedTime } from "@chat/shared";

type VideoProps = {
    message: ZIMVideoMessage
};

export default function Pic(props: VideoProps) {
    const { senderUserID, videoDuration, receiptStatus } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return  <Flex gap={ 4 }>
        <span>{ userInfo?.userName }:</span>
        <VoiceIcon color={ receiptStatus === MessageReceiptStatus.Done ? "#20C164" : "#84969A" } className="item-text-image"></VoiceIcon>
        <span>{ formattedTime(videoDuration * 1000).join(":") }</span>
    </Flex>;
}
