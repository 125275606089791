import { Image, Spin } from "antd";
import MessageState from "./MessageState";
import { CSSProperties, useState } from "react";
import { replaceOssImage } from "@chat/shared";
import { ZIMImageMessage, MessageStatus } from "@chat/zim-sdk";

type ImageMessageProps = {
    data: ZIMImageMessage;
}
export default function PictureMessage(props: ImageMessageProps) {
    const { data } = props;
    const { thumbnailWidth, thumbnailHeight, thumbnailDownloadUrl } = data;
    let localWidth = thumbnailWidth > 200 ? 200 : thumbnailWidth;
    const scale = localWidth / thumbnailWidth;
    const localHeight = thumbnailHeight * scale;
    const style:CSSProperties = { width: Math.round(localWidth) , height:Math.round(Math.min(localHeight, 400)), minWidth: 100 };
    const [loaded, setLoaded] = useState(true);
    const isSending = data.sentStatus === MessageStatus.Sending;
    return <>
        <Spin spinning={ loaded || isSending }>
            <div className="image-message" >
                <Image className="image-message-img"
                       src={ replaceOssImage(thumbnailDownloadUrl) }
                       style={ style }
                       onLoad={ () => setLoaded(false) }
                       placeholder={ <div style={ style } className="common-placeholder"></div> }
                       alt={ thumbnailDownloadUrl }></Image>
                <MessageState data={ data } className="message-state__shadow"/>
            </div>
        </Spin>
    </>;
}
