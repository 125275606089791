import { createHashRouter, Navigate } from "react-router-dom";
import Login from "@/pages/Login";
import Layout from "@/components/layout";
import Message from "@/pages/Message";
import Group from "@/pages/Group";
import Friend from "@/pages/Friend";
import Setting from "@/pages/Setting";
import ImagePage from "@/pages/Image";
import Review from "@/pages/Review";
import Conversation from "@/pages/Message/feature/Conversation";
import Empty from "@/pages/Message/feature/Empty";

const route = createHashRouter([
    {
        path: "/",
        element: <Navigate to="/chat/message"/>
    },
    {
        path: "/chat",
        element: <Layout/>,
        children: [
            {
                path: "message",
                element: <Message/>,
                children: [
                    {
                        index: true,
                        element: <Empty/>
                    },
                    {
                        path: ":conversationID/:conversationType",
                        element: <Conversation/>
                    }
                ]
            },
            {
                path: "group",
                element: <Group/>
            },
            {
                path: "friend",
                element: <Friend></Friend>
            },
            {
                path: "setting",
                element: <Setting></Setting>
            },
            {
                path: "image",
                element: <ImagePage></ImagePage>
            },
            {
                path: "review",
                element: <Review/>
            }
        ]
    },
    {
        path: "/login",
        element: <Login/>
    }
]) as any;

export default route;
