import { Image, Modal } from "antd";
import { hiddenVideoModal } from "@/store/slice/modal.ts";
import { useAppDispatch, useAppSelector } from "@/store";
import closeIcon from "@/assets/svg/modalClose.svg";


function VideoModal() {
    const dispatch = useAppDispatch();
    const { videoModal } = useAppSelector(state => state.model);
    return <Modal width={ 750 }
                  destroyOnClose
                  className="video-modal"
                  footer={ null }
                  open={ videoModal.visible }
                  onCancel={ () => dispatch(hiddenVideoModal()) }
                  closable={ false }
    >
        <div className="header">
            <Image src={ closeIcon } preview={ false } rootClassName="close" onClick={ () => dispatch(hiddenVideoModal())}></Image>
        </div>
        <video autoPlay muted style={ { width: "100%", height: 385 } } src={ videoModal.videoElem.fileDownloadUrl } controls></video>
    </Modal>;
}

export default VideoModal;
