import { GetProps } from "antd";
import Icon from "@ant-design/icons";

function ImageTool() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="2.5" y="3.92505" width="18.8581" height="16.15" rx="1.29776" stroke="currentColor" strokeWidth="2"
              strokeLinejoin="round"/>
        <path
            d="M2.61572 17.1873L6.96599 12.837C7.07923 12.7238 7.25952 12.7138 7.38458 12.8138L9.5744 14.5657C9.70671 14.6715 9.89911 14.6535 10.0094 14.5248L12.7943 11.2757C12.9028 11.1491 13.0914 11.1293 13.2239 11.2306L21.0134 17.1873L21.5 17.5594"
            stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M7.17056 9.4046C7.88727 9.4046 8.46832 8.82355 8.46832 8.10684C8.46832 7.39013 7.88727 6.80908 7.17056 6.80908C6.45385 6.80908 5.8728 7.39013 5.8728 8.10684C5.8728 8.82355 6.45385 9.4046 7.17056 9.4046Z"
              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>;

}

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function ComponentIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ ImageTool } { ...props }></Icon>;
}
