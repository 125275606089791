export const isProd = () => {
    return import.meta.env.VITE_APP_FILE === "prod";
};

export * from "./storage";
export * from "./initStore";
export { default as emitter } from "./emitter";
export * from "./antd";

export enum UserStatusValue {
    OFF_LINE = "0",
    ON_LINE = "1",
    BUSY = "2",
    AWAY = "3",
}
