import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSystemTime, isShowTime } from "@chat/shared";
import { uid } from "uid";
import { UserInfo } from "@/store/slice/common.ts";
import {
    getZimConversationList,
    ZIMConversation,
    queryConversation,
    getMessageList,
    ChatMessageItem,
    type ZIMMessage, MessageReceiptStatus
} from "@chat/zim-sdk";

type GifItem = {
    imgUrl: string;
    tag: string
}
const initialState = {
    conversationList: [] as ZIMConversation[],
    conversationLoading: false,
    messageList: [] as ChatMessageItem[],
    messageLoading: false,
    currentConversation: {} as ZIMConversation,
    quoteMessage: null as ChatMessageItem | null,
    currentVoice: "",
    inputText: "",
    gifList: [] as GifItem[],
    gifSetting: {
        nextPage: 1,
        finish: false
    },
    toolVisible: false,
    currentGroup: null as any | null, // 当前的群
    currentPerson: null as UserInfo | null, // 当前正在聊的人
    currentSettingLoading: false,
    // 登录账户在当前群聊的信息
    selfInGroupInfo: null as any | null,
    chatSettingVisible: true,

};
const createTimeMessage = (time: number) => {
    return {
        localTime: getSystemTime(time),
        contentType: "localTime",
        clientMsgID: uid(),
        sendTime: time
    };
};
const addMergeState = (cur: any, list: any[]) => {
    const len = list.length;
    const message = {
        ...cur,
        isMergeMessage: false
    };
    if (len) {
        const last = list[len - 1];
        const diff = Math.abs((last.sendTime - cur.sendTime) / 1000);
        if (last.contentType === "localTime") {
            return message;
        }
        if (diff < 20 * 60 && last.sendID === cur.sendID) {
            message.isMergeMessage = true;
        }
    }
    return message;
};
// 获取当前会话的详情
export const getConversationDetail = createAsyncThunk(
    "message/conversationDetail",
    async (options: { conversationID: string, conversationType: number }, { dispatch }) => {
        try {
            const res = await queryConversation(options.conversationID, options.conversationType);
            const { conversation } = res;
            dispatch(setCurrentConversation(conversation));
        } catch (err) {
            console.error(err);
        }
    }
);
// 异步action。 获取历史会话列表
export const getConversationList = createAsyncThunk(
    "message/conversationList",
    async () => {
        // const state = getState() as any;
        let imList: ZIMConversation[] = [];

        // 提取im的列表
        try {
            const [res] = await Promise.all(
                [
                    getZimConversationList(),
                ]
            );
            imList = res.conversationList;
        } catch (err) {
            return Promise.reject(err);
        }
        return {
            imList
        };

    }
);
// 异步action。 获取历史会话记录
export const fetchHistoryList = createAsyncThunk(
    "chat/fetchHistoryList",
    async (conversationID: string) => {
        const messageList = await getMessageList(conversationID);
        return {
            messageList: messageList || []
        };
    }
);
export const messageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        updateConversationList(state, action) {
            const list = state.conversationList;
            const data = JSON.parse(JSON.stringify(action.payload));
            const currentIndex = list.findIndex(item => item.conversationID === data.conversationID);
            if (~currentIndex) {
                list.splice(currentIndex, 1, data);
            } else {
                list.push(data);
            }
            // 根据最后一次发送的时间进行排序
            list.sort((a, b) => {
                return b.lastMessage?.timestamp! - a.lastMessage?.timestamp!;
            });

        },
        pushConversationList(state, action) {
            if (state.conversationList.find(item => item.conversationID === action.payload.conversationID)) {
                return;
            }
            const chids: string[] = action.payload.map((item: any) => item.conversationID);
            const filterArr = state.conversationList.filter(item => !chids.includes(item.conversationID));
            state.conversationList = [
                ...action.payload,
                ...filterArr
            ];
        },
        setCurrentConversation: (state, action) => {
            state.currentConversation = JSON.parse(JSON.stringify(action.payload));
        },
        setMessageList(state, action) {
            state.messageList = action.payload;
        },
        addNewMessage(state, action) {
            const list = state.messageList;
            const prev = list[list.length - 1];
            const cur = action.payload as ZIMMessage;
            const addList = [];
            const message = addMergeState(cur, list);
            const showTime = isShowTime(prev ? prev.timestamp : 0, cur.timestamp);
            if (showTime) {
                const timeMessage = createTimeMessage(action.payload.sendTime);
                addList.push(timeMessage);
                message.isMergeMessage = false;
            }
            addList.push(message);
            state.messageList.push(...addList);
        },
        removeMessage(state, action) {
            console.log("remove", action);
            state.messageList = state.messageList.filter(item => item.messageID !== action.payload);
        },
        updateMessageReceipt(state, action) {
            const cur = state.messageList.find(item => item.messageID === action.payload.messageID);
            if (cur) {
                cur.receiptStatus = action.payload.status;
            }
        },
        // 更新信息，默认更新messageID相同，如果是本地走local
        updateMessage(state, action: PayloadAction<{ key: keyof ChatMessageItem; message: ChatMessageItem }>) {
            const { payload } = action;
            const { key = "messageID", message } = payload;
            const updateIndex = state.messageList.findIndex(item => item[key] === message[key]);
            // @ts-ignore
            if (~updateIndex) {
                state.messageList[updateIndex] = {
                    ...state.messageList[updateIndex],
                    ...message
                };
            }
        },
        // 更新列表全部已读
        updateAllMessageRead(state) {
            state.messageList = state.messageList.map(item => {
                return {
                    ...item,
                    isRead: true
                };
            });
        },
        updateMessageRead(state, action) {
            const list = state.messageList;
            const msgIDList = action.payload;
            msgIDList.forEach((id: string) => {
                const cur = list.find(item => item.messageID === id);
                if (cur) {
                    cur.receiptStatus = MessageReceiptStatus.Done;
                }
            });
        },
        setQuoteMessage(state, action) {
            state.quoteMessage = action.payload;
        },
        setCurrentVoice(state, action) {
            state.currentVoice = action.payload;
        },
        updateInputText(state, action) {
            state.inputText = action.payload;
        },
        setGif(state, action: PayloadAction<{ list: GifItem[], nextPage: number; finish: boolean; }>) {
            const { list, nextPage, finish } = action.payload;
            state.gifList.push(...list)
            state.gifSetting = {
                nextPage,
                finish
            };
        },
        toggleToolVisible(state) {
            state.toolVisible = !state.toolVisible;
        },
        setToolVisible(state, action) {
            state.toolVisible = action.payload;
        },
        // 设置当前聊天群详情
        setCurrentGroup(state, action) {
            state.currentGroup = action.payload;
        },
        setCurrentPerson(state, action) {
            state.currentPerson = action.payload;
        },
        setCurrentSettingLoading(state, action) {
            state.currentSettingLoading = action.payload;
        },
        setChatSettingVisible(state, action) {
            state.chatSettingVisible = action.payload;
        },
        setSelfInGroupInfo(state, action) {
            state.selfInGroupInfo = action.payload;
        },
        // 切换 conversation
        reset(state) {
            return {
                ...initialState,
                conversationList: state.conversationList,
                gifList: state.gifList
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getConversationList.pending, state => {
            state.conversationLoading = true;
        });
        builder.addCase(getConversationList.fulfilled, (state, action) => {
            const { imList } = action.payload;
            const list = [...imList];
            state.conversationLoading = false;
            state.conversationList = list;
        });
        builder.addCase(getConversationList.rejected, state => {
            state.conversationLoading = false;
        });

        builder.addCase(fetchHistoryList.pending, state => {
            state.messageLoading = true;
        });
        builder.addCase(fetchHistoryList.fulfilled, (state, { payload }) => {
            const { messageList } = payload;
            state.messageLoading = false;
            state.messageList = messageList;
            // setTimeout(() => {
            //     emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "auto" });
            // }, 20);
        });
        builder.addCase(fetchHistoryList.rejected, (state) => {
            state.messageLoading = false;
        });
    }
});

export const {
    setCurrentConversation,
    addNewMessage,
    updateMessage,
    removeMessage,
    updateMessageReceipt,
    updateAllMessageRead,
    updateMessageRead,
    setQuoteMessage,
    setCurrentVoice,
    updateConversationList,
    pushConversationList,
    updateInputText,
    setGif,
    toggleToolVisible,
    setToolVisible,
    setCurrentGroup,
    setCurrentPerson,
    setChatSettingVisible,
    setSelfInGroupInfo,
    setMessageList,
    setCurrentSettingLoading,
    reset
} = messageSlice.actions;
export default messageSlice.reducer;
