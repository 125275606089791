import { Layout, Spin } from "antd";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@/store";
import MessageItemCop from "./MessageItem";
import Notice from "@/pages/Message/feature/Conversation/Notice";
import SystemMessage from "@/pages/Message/feature/Conversation/MessageItem/SystemMessage";
import emitter from "@/utils/emitter";
import { useThrottleFn } from "ahooks";
import ToBottom from "@/components/icon/ToBottom.tsx";
import useMarkRead from "@/hooks/useMarkRead.ts";
import { AnimatePresence, motion } from "framer-motion";
import useChatInit from "@/hooks/useChatInit.ts";
import { ChatMessageItem, SystemMessageTypes } from "@chat/zim-sdk";


function Content() {
    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const params = useParams();
    const [chatInit] = useChatInit();
    const { conversationID, conversationType } = params;
    const [markRead] = useMarkRead(conversationID!);
    const { messageList, messageLoading } = useAppSelector(state => state.message);
    const { userInfo } = useAppSelector(state => state.common);
    // 获取当前会话详细信息
    const rowRenderer = (_: any, data: ChatMessageItem) => {
        if (SystemMessageTypes.includes(data.type as any)) {
            return <SystemMessage data={ data }></SystemMessage>;
        }
        return <MessageItemCop data={ data } key={ data.messageID } isSelf={ userInfo?.userID === data.senderUserID }/>;
    };
    useEffect(() => {
        chatInit();
    }, [conversationID, userInfo?.userID, conversationType]);
    const handleToBottom = (config?: { behavior?: "smooth" | "auto", index?: number }) => {
        const { behavior = "auto", index } = config || {};
        setTimeout(() => {
            virtuosoRef.current?.scrollToIndex({
                index: index ? index : messageList.length - 1,
                behavior
            });
            // 标记已读
            markRead();
        }, 50);
    };
    const [bottomBtn, setBottomBtn] = useState(false);
    // 导航栏滚动到最底部显示按钮
    const { run: handleScroll } = useThrottleFn((newItem = false) => {
        const target = document.querySelector("#virtual") as HTMLDivElement;
        const limitValue = 20;
        if (!target) return;
        const scrolltop = target.scrollTop;
        const offsetHeight = target.offsetHeight;
        const scrollHeight = target.scrollHeight;
        if (scrolltop + offsetHeight + limitValue < scrollHeight) {
            if (bottomBtn) return;
            setBottomBtn(true);
        } else {
            if (newItem) {
                markRead();
            }
            if (!bottomBtn) return;
            // 标记已读
            markRead();
            setBottomBtn(false);
        }
    }, { wait: 500 });
    // 监听到数据更新
    const addMessage = () => {
        if (!bottomBtn) {
            handleToBottom({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        emitter.on("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
        emitter.on("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        return () => {
            emitter.off("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
            emitter.off("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        };
    }, [addMessage]);
    useEffect(() => {
        handleScroll(true);
    }, [messageList]);
    useEffect(() => {
        if (!messageLoading) {
            handleToBottom();
        }
    }, [messageLoading]);
    // useEffect(() => {
    //     markRead();
    // }, [conversationID]);
    return <Spin spinning={ messageLoading } wrapperClassName="content-spin">
        <Layout.Content className="conversation-content">
            <Notice/>
            <div className="conversation-content-main">
                <Virtuoso ref={ virtuosoRef }
                          data={ messageList }
                          id="virtual"
                          className="message-main"
                          increaseViewportBy={ 1000 }
                          onScroll={ handleScroll }
                          computeItemKey={ (_, item) => item.messageID }
                          totalCount={ messageList.length }
                          itemContent={ rowRenderer }
                />

                <AnimatePresence>

                    {
                        bottomBtn &&
                        <motion.div exit={ { opacity: 0, y: -20, x: -30 } }
                                    initial={ { opacity: 0, y: 20, x: -30 } }
                                    animate={ { opacity: 1, y: 0, x: -30 } }
                                    onClick={ () => handleToBottom() }>
                            <ToBottom className="chat-to-bottom"/>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </Layout.Content>
    </Spin>;
}

export default Content;
