import React, { memo } from "react";
// import { useAppSelector } from "@/store";
// import useThemeColor from "@/components/themeSvg/useThemeColor";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    self: boolean;
}

function ThemeIcon(props: IconProps) {
    const { self, ...attrs } = props;
    // const [t3Color] = useThemeColor("--whats-color-t3")
    // const { value } = useAppSelector(state => state.common.theme);
    // 判断是否自己的
    // let color = self ? value["--whats-main-bubble"] : value["--whats-sub-bubble"];
    return <span role="img" { ...attrs }>
             <svg viewBox="0 0 14 21" fill="none">
                <path
                    d="M7.06709 12.7365C8.2701 12.7365 9.23396 11.7654 9.23396 10.5624L9.24121 4.51274C9.24121 3.30973 8.2701 2.33862 7.06709 2.33862C5.86408 2.33862 4.89297 3.30973 4.89297 4.51274V10.5624C4.89297 11.7654 5.86408 12.7365 7.06709 12.7365ZM11.524 10.5624C11.1838 10.5624 10.9138 10.8403 10.8576 11.1758C10.5495 13.0175 8.87328 14.2584 7.06709 14.2584C5.2609 14.2584 3.58472 13.0175 3.27656 11.1758C3.22041 10.8403 2.95035 10.5624 2.61014 10.5624V10.5624C2.26993 10.5624 1.98987 10.8397 2.03514 11.1768C2.32899 13.3659 4.1655 15.1072 6.34238 15.4325V17.9634C6.34238 18.3636 6.66685 18.6881 7.06709 18.6881V18.6881C7.46733 18.6881 7.7918 18.3636 7.7918 17.9634V15.4325C9.96922 15.1138 11.8061 13.3722 12.0993 11.1769C12.1443 10.8397 11.8642 10.5624 11.524 10.5624V10.5624Z"
                    fill="#0085FF"/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M4.8429 16.5904C2.63013 15.7841 0.876993 13.8199 0.548992 11.3764C0.36484 10.0046 1.50452 9.06242 2.61066 9.06242C2.8969 9.06242 3.1586 9.11541 3.39349 9.20817V4.51274C3.39349 2.4813 5.03617 0.838623 7.06761 0.838623C9.09905 0.838623 10.7417 2.4813 10.7417 4.51274V9.20817C10.9766 9.11541 11.2383 9.06242 11.5246 9.06242C12.6307 9.06242 13.7704 10.0046 13.5862 11.3764C13.2582 13.8199 11.5051 15.7841 9.29232 16.5904V17.9634C9.29232 19.192 8.29628 20.1881 7.06761 20.1881C5.83894 20.1881 4.8429 19.192 4.8429 17.9634V16.5904ZM7.0667 12.7365C5.86369 12.7365 4.89258 11.7654 4.89258 10.5624V4.51274C4.89258 3.30973 5.86369 2.33862 7.0667 2.33862C8.26971 2.33862 9.24082 3.30973 9.24082 4.51274V10.5624C9.24082 11.7654 8.26971 12.7365 7.0667 12.7365ZM3.38481 11.6316C3.33968 11.4843 3.30365 11.3323 3.27749 11.1759C3.26827 11.1208 3.25328 11.0673 3.23315 11.0162C3.13074 10.7566 2.8954 10.5625 2.61107 10.5625C2.27086 10.5625 1.9908 10.8397 2.03607 11.1769C2.04514 11.2445 2.05568 11.3117 2.06766 11.3784C2.10376 11.5794 2.1529 11.7763 2.2141 11.9683C2.78523 13.7598 4.40715 15.1233 6.29273 15.4247L6.34331 15.4325V17.9634C6.34331 18.3637 6.66778 18.6882 7.06802 18.6882C7.46826 18.6882 7.79273 18.3637 7.79273 17.9634V15.4325L7.84331 15.4247C9.72889 15.1233 11.3508 13.7598 11.9219 11.9683C11.9831 11.7763 12.0323 11.5794 12.0684 11.3784C12.0804 11.3117 12.0909 11.2445 12.1 11.1769C12.1452 10.8397 11.8652 10.5625 11.525 10.5625C11.2406 10.5625 11.0053 10.7566 10.9029 11.0162C10.8828 11.0673 10.8678 11.1208 10.8586 11.1759C10.8324 11.3323 10.7964 11.4843 10.7512 11.6316C10.3421 12.9672 9.18451 13.9147 7.84331 14.1819C7.76668 14.1971 7.68945 14.2102 7.61173 14.221C7.43269 14.2458 7.25102 14.2585 7.06802 14.2585C6.88502 14.2585 6.70335 14.2458 6.52431 14.221C6.44659 14.2102 6.36936 14.1971 6.29273 14.1819C4.95153 13.9147 3.79398 12.9672 3.38481 11.6316Z"
                      fill="white"/>
            </svg>
    </span>;
}

export default memo(ThemeIcon);
