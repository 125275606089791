import Icon from "@ant-design/icons";
import { GetProps } from "antd";
// B1B9C4
const MessageSvg = () => <svg width="28" height="29" viewBox="0 0 28 29">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M20.871 6.5C22.8664 6.5 24.4839 8.11753 24.4839 10.1128V20.9511C24.4839 22.9464 22.8664 24.5639 20.871 24.5639H10.8387L8.60421 27.314C8.24276 27.7588 7.5637 27.7588 7.20224 27.314L4.96774 24.5639H3.61285C1.61753 24.5639 0 22.9464 0 20.9511V10.1128C0 8.11753 1.61752 6.5 3.61284 6.5H20.871ZM5.41935 17.3378C6.41703 17.3378 7.2258 16.529 7.2258 15.5314C7.2258 14.5337 6.41703 13.725 5.41935 13.725C4.42168 13.725 3.6129 14.5337 3.6129 15.5314C3.6129 16.529 4.42168 17.3378 5.41935 17.3378ZM13.5484 15.5314C13.5484 16.529 12.7396 17.3378 11.7419 17.3378C10.7443 17.3378 9.93549 16.529 9.93549 15.5314C9.93549 14.5337 10.7443 13.725 11.7419 13.725C12.7396 13.725 13.5484 14.5337 13.5484 15.5314ZM18.0645 17.3378C19.0622 17.3378 19.871 16.529 19.871 15.5314C19.871 14.5337 19.0622 13.725 18.0645 13.725C17.0668 13.725 16.2581 14.5337 16.2581 15.5314C16.2581 16.529 17.0668 17.3378 18.0645 17.3378Z"
              fill="currentColor" fillOpacity="0.6"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.12896 1.5C6.13364 1.5 4.51611 3.11753 4.51611 5.11284V14.1447C4.51611 16.14 6.13364 17.7575 8.12896 17.7575H17.1613L19.3958 20.5076C19.7572 20.9525 20.4363 20.9525 20.7978 20.5076L23.0322 17.7575H24.3871C26.3825 17.7575 28 16.14 28 14.1447V5.11284C28 3.11753 26.3825 1.5 24.3872 1.5H8.12896ZM9.93547 11.4352C10.9331 11.4352 11.7419 10.6264 11.7419 9.62877C11.7419 8.63112 10.9331 7.82238 9.93547 7.82238C8.93779 7.82238 8.12902 8.63112 8.12902 9.62877C8.12902 10.6264 8.93779 11.4352 9.93547 11.4352ZM18.0645 9.62877C18.0645 10.6264 17.2557 11.4352 16.2581 11.4352C15.2604 11.4352 14.4516 10.6264 14.4516 9.62877C14.4516 8.63112 15.2604 7.82238 16.2581 7.82238C17.2557 7.82238 18.0645 8.63112 18.0645 9.62877ZM22.5806 11.4352C23.5783 11.4352 24.3871 10.6264 24.3871 9.62877C24.3871 8.63112 23.5783 7.82238 22.5806 7.82238C21.583 7.82238 20.7742 8.63112 20.7742 9.62877C20.7742 10.6264 21.583 11.4352 22.5806 11.4352Z"
              fill="currentColor"/>
    </svg>

;
type CustomIconComponentProps = GetProps<typeof Icon>;

export default function MessageIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ MessageSvg } { ...props }></Icon>;
}
