const packageList = [
    "https://indusbet-test.pages.dev"
];
// 根据域名获取当前的渠道
const getDomain = () => {
    const { protocol, hostname } = window.location;
    return `${ protocol }//${ hostname }`;
};

export const getPackage = () => {
    const url = getDomain()
    const packageId = packageList.findIndex((item) => item === url)
    return packageId === -1 ? 5 : packageId;
};
