import React, { memo } from "react";
interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {

    return <span role="img" { ...props }>
<svg viewBox="0 0 32 32" fill="none">
  <circle cx="16" cy="16" r="16" fill="white"/>
  <circle cx="16" cy="16" r="15.7475" stroke="#E7E7E7" strokeOpacity="0.15" strokeWidth="0.504993"/>
  <path d="M11.9609 11.7095L16.332 15.2849L20.0408 11.7095" stroke="#566369" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path d="M11.9609 16.7122L16.332 20.2875L20.0408 16.7122" stroke="#566369" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
