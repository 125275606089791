import { ConfigProvider, App as AntApp } from "antd";
import { RouterProvider } from "react-router-dom";
import router from "@/router";
import antThemeConfig from "@/theme/antd";
import { Provider } from "react-redux";
import store from "@/store";
import "wc-waterfall";

function App() {
    return (
        <Provider store={ store }>
            <ConfigProvider theme={ antThemeConfig }>
                <AntApp>
                    <RouterProvider router={ router }/>
                </AntApp>
            </ConfigProvider>
        </Provider>
    );
}

export default App;
