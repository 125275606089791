import { useTranslation } from "react-i18next";

function Empty() {
    const { t } = useTranslation();
    return <div className="conversation-empty">
        <div className="conversation-empty-text">{ t("common.empty") }</div>
    </div>;
}

export default Empty;
