import React, { memo } from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    read?: boolean;
}

const readIcon = <svg viewBox="0 0 17 17" fill="none">
    <path
        d="M4.79995 12.7871L1.09912 9.08576L2.08253 8.10288L4.6872 10.7075L10.6498 3.28711L11.7334 4.15777L4.79995 12.7871Z"
        fill="#1FA2FF"/>
    <path
        d="M9.16314 12.7871L7.36023 10.9842L8.23611 9.89326L9.05039 10.7075L15.0135 3.28711L16.0971 4.15777L9.16314 12.7871Z"
        fill="#1FA2FF"/>
</svg>;

const unreadIcon = <svg viewBox="0 0 17 17" fill="none">
    <g id="Frame 1000006423">
        <g id="double tick">
            <path id="Vector"
                  d="M4.79995 12.7871L1.09912 9.08576L2.08253 8.10288L4.6872 10.7075L10.6498 3.28711L11.7334 4.15777L4.79995 12.7871Z"
                  fill="#87959E"/>
            <path id="Vector_2"
                  d="M9.16314 12.7871L7.36023 10.9842L8.23611 9.89326L9.05039 10.7075L15.0135 3.28711L16.0971 4.15777L9.16314 12.7871Z"
                  fill="#87959E"/>
        </g>
    </g>
</svg>;


function ThemeIcon(props: IconProps) {
    const { read, ...attrs } = props;

    return <span role="img" { ...attrs }>
        {
            read ? readIcon : unreadIcon
        }
    </span>;
}

export default memo(ThemeIcon);
