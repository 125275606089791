import { Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
    color: string;
    onLine: boolean;
}

function NetIcon(props: IconProps) {
    const { color, onLine, ...attrs } = props;
    return <div { ...attrs }>
        <svg viewBox="0 0 24 25" fill="none">
            <path d="M2 9.98265C2.2944 9.70365 2.59761 9.4393 2.9087 9.1896C8.51855 4.68711 16.6911 4.95146 22 9.98265"
                  stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19 13.3995C15.134 9.5335 8.866 9.5335 5 13.3995" stroke={ color } strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 16.6569C13.7908 14.4477 10.2092 14.4477 8 16.6569" stroke={ color } strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12 20.5C12.6904 20.5 13.25 19.9404 13.25 19.25C13.25 18.5597 12.6904 18 12 18C11.3097 18 10.75 18.5597 10.75 19.25C10.75 19.9404 11.3097 20.5 12 20.5Z"
                  fill={ color }/>

            {
                !onLine && <g filter="url(#filter0_d_1981_3836)">
                    <path d="M8.29684 20.4789L17 4" stroke="#A52A2A" strokeWidth="2" strokeLinecap="round"/>
                </g>
            }

            <defs>
                <filter id="filter0_d_1981_3836" x="7.29663" y="2.99976" width="11.7036" height="19.4794"
                        filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dx="1" dy="1"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.254902 0 0 0 0 0.337255 0 0 0 1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1981_3836"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1981_3836" result="shape"/>
                </filter>
            </defs>
        </svg>
    </div>;
}

const colorList = [
    "#00FF00",
    "#FF0",
    "#FFA500",
    "#FF0000",
    "#A52A2A",
];

export default function NetWork() {
    const timer = useRef<NodeJS.Timer>();
    const [onLineStatus, setLineStatus] = useState({
        isOnLine: true,
        latency: 0
    });
    const getNeyWorkData = () => {
        if (_.inRange(onLineStatus.latency, 0, 100)) {
            return {
                color: colorList[0],
            };
        }
        if (_.inRange(onLineStatus.latency, 100, 200)) {
            return {
                color: colorList[1],
            };
        }
        if (_.inRange(onLineStatus.latency, 200, 500)) {
            return {
                color: colorList[2],
            };
        }
        if (_.inRange(onLineStatus.latency, 500, 4999)) {
            return {
                color: colorList[3],
            };
        }
        if (!onLineStatus.isOnLine) {
            return {
                color: colorList[4],
            };
        }
        return {
            color: colorList[0]
        };
    };
    const checkInternetConnection = () => {
        const startTime = Date.now();
        fetch("/vite.svg", { method: "HEAD" }).then(response => {
            if (response.ok) {
                let Latency = Date.now() - startTime;
                setLineStatus({
                    isOnLine: true,
                    latency: Latency
                });
            } else {
                setLineStatus({
                    isOnLine: false,
                    latency: 10000
                });
            }
        }).catch(() => {
            setLineStatus({
                isOnLine: false,
                latency: 10000
            });
        });
    };
    const startTimer = () => {
        timer.current = setTimeout(() => {
            checkInternetConnection();
            startTimer();
        }, 1000 * 5);
    };
    useEffect(() => {
        checkInternetConnection();
        startTimer();
        return () => {
            timer.current && clearTimeout(timer.current);
        };
    }, []);
    const status = getNeyWorkData();
    return <div className="network">
        <NetIcon className="network-icon" color={ status.color } onLine={ true }/>
        {
            onLineStatus.isOnLine ? <Space size={ 2 } style={ { color: status.color } }>
                <div>{ onLineStatus.latency > 500 ? '>500' : onLineStatus.latency }</div>
                <div>ms</div>
            </Space> : <div style={ { color: colorList[3] } }>Offline</div>
        }
    </div>;
}
