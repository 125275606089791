import MessageState from "./MessageState";
import { ZIMMessage } from "@chat/zim-sdk";

type TextMessageProps = {
    data: ZIMMessage
}

function TextMessage(props: TextMessageProps) {
    const { data } = props;
    return <div className="message-text">
        { data.message }
        <MessageState data={ data } className="message-state__right"/>
    </div>;
}

export default TextMessage;
