import { Avatar, Badge, Flex, List, Skeleton, Typography } from "antd";
import { formatConversionTime, replaceOssImage } from "@chat/shared";
import { useAppDispatch, useAppSelector } from "@/store";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import {
    ConversationType,
    ZIMConversation,
    addConversationListener,
    type ConversationListener,
    removeConversationListener, MessageReceiptStatus, getConversationType
} from "@chat/zim-sdk";
import LastMessage from "@/pages/Message/feature/Sider/LastMessage";
import { useEffect } from "react";
import { updateConversationList } from "@/store/slice/message";
import { ReadStatus } from "@/components/icon";

const { Paragraph } = Typography;
export default function Sider() {
    const params = useParams();
    const navigate = useNavigate();
    const { conversationID } = params;
    const { conversationLoading, conversationList } = useAppSelector(state => state.message);
    const { isLogining } = useAppSelector(state => state.common);

    // pc端只展示单聊
    const filterList = conversationList.filter(item => item.type === ConversationType.Peer);
    const handleClick = (values: ZIMConversation) => {
        navigate(`/chat/message/${ values.conversationID }/${ values.type }`, { replace: true });
    };
    const dispatch = useAppDispatch();
    const listener = () => {
        const onConversationChanged: ConversationListener["onConversationChanged"] = (infoList) => {
            infoList.forEach(item => {
                console.log("conversation更新", item);
                if (item.conversation.type === ConversationType.Peer) {
                    dispatch(updateConversationList(item.conversation));
                }
            });
        };
        const onTotalUnreadMessageCountChanged: ConversationListener["onTotalUnreadMessageCountChanged"] = (totalUnreadMessageCount: number) => {
            console.log(totalUnreadMessageCount, "--->unread");
        };
        addConversationListener({
            onConversationChanged,
            onTotalUnreadMessageCountChanged
        });
        return () => removeConversationListener();
    };
    // 在这里进行监听会话变更
    useEffect(() => {
        if (isLogining) {
            return;
        }
        const removeListener = listener();
        return () => {
            removeListener();
        };
    }, []);
    return <div className="sider">
        <List itemLayout="horizontal" loading={ conversationLoading } dataSource={ filterList }
              renderItem={ (item) => {
                  const classname = classnames({
                      "conversation-item": true,
                      "conversation-item__checked": conversationID === item.conversationID
                  });
                  return <List.Item className={ classname } onClick={ handleClick.bind(null, item) }>
                      <Skeleton loading={ conversationLoading } avatar active>
                          <List.Item.Meta
                              avatar={ <Badge size="small" count={ item.unreadMessageCount }>
                                  <Avatar size={ 48 } src={ replaceOssImage(item.conversationAvatarUrl) }></Avatar>
                              </Badge> }
                              title={ <Flex justify="space-between" className="title" align="center">
                                  <div className="showName">{ item.conversationName }</div>
                                  <div
                                      className="time">{ formatConversionTime(item.lastMessage?.timestamp!, true) }</div>
                              </Flex> }
                              description={ item.lastMessage &&
                                  <Flex align="center" gap={ 2 }>
                                      <Paragraph ellipsis={ { rows: 1 } } className="item-text">
                                          <LastMessage message={ item.lastMessage }></LastMessage>
                                      </Paragraph>
                                      {
                                          +getConversationType(item.conversationID) === ConversationType.Peer &&
                                          <ReadStatus
                                              read={ item.lastMessage.receiptStatus === MessageReceiptStatus.Done }
                                              style={ { display: "inline-block", width: 16, height: 16, flexShrink: 0 } }/>
                                      }
                                  </Flex>
                              }
                          ></List.Item.Meta>
                      </Skeleton>
                  </List.Item>;
              } }></List>

    </div>;
}
