import React, { memo } from "react";

interface IconProps extends React.HTMLProps<HTMLDivElement> {
}

function ThemeIcon(props: IconProps) {

    return <span role="img" { ...props }>
<svg viewBox="0 0 22 22" color="#566369">
  <rect x="2.45514" y="1" width="6.34432" height="20" rx="1" fill="currentColor"/>
  <rect x="13.2012" y="1" width="6.34432" height="20" rx="1" fill="currentColor"/>
</svg>
    </span>;
}

export default memo(ThemeIcon);
