import { useAppDispatch, useAppSelector } from "@/store";
import { ExpressType } from "./type";
import cls from "./imageTool.module.scss";
import { replaceOssImage } from "@chat/shared";
import { useEffect } from "react";
import { useLatest, useRequest, useThrottleFn } from "ahooks";
import { getGifPage } from "@/api/im";
import { setGifList, setPaginate } from "@/store/slice/git";
import { Spin } from "antd";

type GifBoxProps = {
    chooseItem: (type: ExpressType, url: string) => void;
}
const useGifPage = (selector: string) => {
    const dispatch = useAppDispatch();
    const { list, paginate } = useAppSelector(state => state.gif);
    const localPaginate = useLatest(paginate);
    const { run, loading } = useRequest(getGifPage, {
        manual: true,
        onSuccess: (res, [params]) => {
            const { data } = res;
            if (res.code === 0) {
                const { totalPages, content, totalSize } = data;
                dispatch(setGifList(content));
                dispatch(setPaginate({
                    pageSize: params.pageSize,
                    totalSize,
                    currentPage: params.pageNo,
                    totalPage: totalPages
                }));
            }
        },
    });
    const loadMore = () => {
        const { currentPage, totalPage, pageSize } = localPaginate.current;
        if (!loading && currentPage < totalPage) {
            const params = { pageNo: currentPage + 1, pageSize };
            run(params);
        }
    };
    const { run: scrollFn } = useThrottleFn(() => {
        const imageTool = document.querySelector(selector) as HTMLDivElement;
        const finish = localPaginate.current.currentPage >= localPaginate.current.totalPage;
        if (imageTool.scrollTop + imageTool.clientHeight + 30 >= imageTool.scrollHeight && !finish) {
            loadMore();
        }
    }, {
        wait: 400
    });
    useEffect(() => {
        const imageTool = document.querySelector(selector) as HTMLDivElement;
        if (imageTool) {
            if (localPaginate.current.currentPage === 0) {
                scrollFn();
            }
            imageTool.addEventListener("scroll", scrollFn);
            return () => {
                imageTool.removeEventListener("scroll", scrollFn);
            };
        }
    }, []);
    return {
        loading,
        loadMore,
        list
    };
};
export default function GifBox(props: GifBoxProps) {
    const { list, loading } = useGifPage("#imageTool");
    const { chooseItem } = props;
    return <div className={ cls.gifBox }>
        <wc-waterfall cols={ 4 } gap={ 10 } className={ cls.gifBox__content }>
            {
                list.map(item => {
                    return <img
                        onClick={ () => chooseItem(ExpressType.GIF, item.imgUrl) }
                        className={ cls.gifBox__item }
                        src={ replaceOssImage(item.imgUrl) }
                        key={ item.imgUrl }
                        alt=""/>;
                })
            }
        </wc-waterfall>
        {
            loading && <Spin size="small" tip="loading"><div></div></Spin>
        }

    </div>;
}
