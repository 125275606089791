import { Typography, Image, Space } from "antd";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/store";
import cardIcon from "@/assets/svg/customCard.svg";
import { setQuoteMessage } from "@/store/slice/message";
import voiceIcon from "@/assets/svg/voice-message.svg";
import picIcon from "@/assets/svg/photo.svg";
import { formattedTime } from "@chat/shared";
import cls from "./quote.module.scss";
import { CloseIcon } from "@/components/icon";
import VideoIcon from "@/assets/svg/video-quote.svg";
import { CustomType, MessageType, ZIMAudioMessage, ZIMCustomMessage, ZIMVideoMessage } from "@chat/zim-sdk";
import { useUserInfo } from "@/hooks/useUserInfo.ts";

const { Paragraph } = Typography;

function Quote() {
    const { userInfo } = useAppSelector(state => state.common);
    const { quoteMessage } = useAppSelector(state => state.message);
    const { userInfo: sendUser } = useUserInfo(quoteMessage?.senderUserID!)
    const dispatch = useAppDispatch();
    if (!quoteMessage || !userInfo) return null;
    const isSelf = userInfo.userID === quoteMessage.senderUserID;

    const renderContent = () => {
        switch (quoteMessage.type) {
            // 文本
            case MessageType.Text:
                return <Paragraph ellipsis={ { rows: 2 } }>
                    { quoteMessage.message }
                </Paragraph>;
            case MessageType.Audio:
                const audioMessage = quoteMessage as ZIMAudioMessage;
                const time = formattedTime(audioMessage.audioDuration * 1000).join(":");
                return <Space size={ 3 }>
                    <Image className={ cls.replyContent__icon } preview={ false } src={ voiceIcon } alt="" width={ 12 }
                           height={ 16 }/>
                    <span>Voice Message ({ time })</span>
                </Space>;
            case MessageType.Video:
                const videoMessage = quoteMessage as ZIMVideoMessage;
                const Videotime = formattedTime(videoMessage.videoDuration * 1000).join(":");
                return <Space>
                    <Image className={ cls.replyContent__icon } preview={ false } src={ VideoIcon } alt="" width={ 16 }
                           height={ 16 }/>
                    <span>Voice ({ Videotime })</span>
                </Space>;
            case MessageType.Image:
                return <Space size={ 3 }>
                    <Image className={ cls.replyContent__icon } preview={ false } src={ picIcon } alt="" width={ 16 }
                           height={ 16 }/>
                    <span>Photo</span>
                </Space>;
            case MessageType.Custom:
                const customMessage = quoteMessage as ZIMCustomMessage;
                if (customMessage.subType === CustomType.Card) {
                    return <Space size={ 3 }>
                        <Image src={ cardIcon } preview={ false }></Image>
                        <span>Customer Card</span>
                    </Space>;
                }
                return <Space size={ 3 }>
                    <Image src={ cardIcon } preview={ false }></Image>
                    <span>Bet Slip</span>
                </Space>;

            default:
                return "Error Type:" + quoteMessage.type;
        }
    };
    return <div className={ classNames(cls.replyQuote, { [cls.replyQuote__self]: isSelf }) }>
        {/*<Image className={ cls.replyQuote__left } src={ replyIcon } width={ 24 } height={ 24 }></Image>*/ }
        <div className={ cls.replyContent }>
            <div className={ cls.replyContent__name }>{ isSelf ? "YOU" : sendUser?.userName }</div>
            { renderContent() }
        </div>
        <CloseIcon onClick={ () => dispatch(setQuoteMessage(null)) } className={ cls.replyClose }/>
    </div>;
}

export default Quote;
