import { useUserInfo } from "@/hooks/useUserInfo";
import { ZIMAudioMessage, MessageReceiptStatus } from "@chat/zim-sdk";
import Voice from "@/components/icon/Voice";
import { formattedTime } from "@chat/shared";
import { Flex } from "antd";

type AudioProps = {
    message: ZIMAudioMessage
};

export default function Audio(props: AudioProps) {
    const { senderUserID, receiptStatus, audioDuration } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return <Flex gap={ 4 }>
        <span>{ userInfo?.userName }:</span>
        <Voice color={ receiptStatus === MessageReceiptStatus.Done ? "#20C164" : "#84969A" }
               className="text-inner-image"></Voice>
        <span>{ formattedTime(audioDuration * 1000).join(":") }</span>
    </Flex>;
}
