import { Flex, Image, Space } from "antd";
import refreshIcon from "@/assets/svg/refresh.svg";
import moreIcon from "@/assets/svg/more.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/store";
import { setChatSettingVisible } from "@/store/slice/message.ts";
import useChatInit from "@/hooks/useChatInit.ts";

function Header() {
    const { t } = useTranslation();
    const { currentConversation, chatSettingVisible } = useAppSelector(state => state.message);
    const dispatch = useAppDispatch();
    const [refresh] = useChatInit();
    const showSetting = () => {
        dispatch(setChatSettingVisible(!chatSettingVisible));
    };
    return <Flex className="conversation-header" justify="space-between" align="center">
        <div className="text">{ currentConversation.conversationName }</div>
        <Space size={ 20 } className={ "conversation-header-right" }>
            <Space style={ { cursor: "pointer" } } onClick={ refresh }>
                <Image preview={ false } width={ 24 } height={ 24 } src={ refreshIcon }/>
                <div>
                    { t("common.refresh") }
                </div>
            </Space>
            <Image style={ { cursor: "pointer" } } onClick={ showSetting } src={ moreIcon } width={ 24 } height={ 24 }
                   preview={ false }/>
        </Space>
    </Flex>;
}

export default Header;
