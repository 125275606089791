import cls from "@/pages/Message/feature/Setting/setting.module.scss";
import { Image, Spin, Typography } from "antd";
import closeIcon from "@/assets/svg/close.svg";
import { copyableProps } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import { setChatSettingVisible } from "@/store/slice/message.ts";

const { Paragraph } = Typography;
const getNumberText = (val: string) => {
   return val.replace(/[^0-9]/g, "")
}
export default function Person() {
    const currentPerson = useAppSelector(state => state.message.currentPerson);
    const currentSettingLoading = useAppSelector(state => state.message.currentSettingLoading);
    const dispatch = useAppDispatch();
    const handleClose = () => {
        dispatch(setChatSettingVisible(false));
    };
    const thirdID = getNumberText(currentPerson?.userID || "")
    return <div className={ cls.setting }>
        <div className={ cls.header }>
            {/*<Col>*/ }
            {/*    <Image src={ arrowIcon } preview={ false }/>*/ }
            {/*</Col>*/ }
            <div className={ cls.header__text }>
                User information
            </div>
            <Image wrapperClassName={ cls.header__close } onClick={ handleClose } src={ closeIcon } preview={ false }/>
        </div>
        <Spin spinning={ currentSettingLoading }>
            <div className={ cls.userInfo }>
                <div className={ cls.item }>
                    <div className={ cls.label }>Nick Name</div>
                    <div className={ cls.value }>{ currentPerson?.userName }</div>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label }>User ID</div>
                    <Paragraph className={ cls.value }
                               copyable={ copyableProps(thirdID) }>{ thirdID }</Paragraph>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label }>IM ID</div>
                    <Paragraph className={ cls.value }
                               copyable={ copyableProps(currentPerson?.userID || "") }>{ currentPerson?.userID }</Paragraph>
                </div>
                {/*<div className={ cls.item }>*/}
                {/*    <div className={ cls.label }>Phone</div>*/}
                {/*    <Paragraph className={ cls.value }*/}
                {/*               copyable={ copyableProps(currentPerson?.phone || "") }>{ currentPerson?.phone || "---" }</Paragraph>*/}
                {/*</div>*/}
            </div>
        </Spin>
    </div>;
}
