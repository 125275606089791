import { GetProps } from "antd";
import Icon from "@ant-design/icons";

function QuickIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M9.25183 20.9129L9.02193 20.8436L8.78562 20.8862L4.46134 21.6666L4.46109 21.6667C4.43859 21.6708 4.4155 21.6701 4.39325 21.6649C4.37102 21.6597 4.35009 21.6499 4.33178 21.6362C4.31347 21.6225 4.29817 21.6052 4.28685 21.5854C4.27811 21.5701 4.27188 21.5535 4.26835 21.5363L4.26501 21.4712L4.29857 17.7008L4.30134 17.39L4.12748 17.1324C3.16302 15.7035 2.60563 14.039 2.51507 12.3174C2.42451 10.5959 2.80419 8.88209 3.61341 7.35985C4.42264 5.83761 5.63088 4.5643 7.10863 3.67643C8.58629 2.78861 10.2777 2.31965 12.0015 2.31982C13.3656 2.32101 14.7133 2.61596 15.9532 3.18461C17.1932 3.75331 18.2961 4.58243 19.1869 5.61554C20.0778 6.64866 20.7356 7.86154 21.1157 9.17167C21.4959 10.4818 21.5893 11.8584 21.3898 13.2079C21.1902 14.5574 20.7024 15.8481 19.9593 16.9921C19.2163 18.1362 18.2356 19.1068 17.0839 19.8378C15.9322 20.5689 14.6365 21.0434 13.285 21.2289L13.421 22.2196L13.285 21.2289C11.9335 21.4144 10.5579 21.3066 9.25183 20.9129Z"
            stroke="currentColor" strokeWidth="2"/>
        <path
            d="M13.8377 7.24851C13.8692 7.12228 13.7738 7 13.6436 7H10.0543C9.96331 7 9.88377 7.06145 9.8608 7.14952L8.36515 12.8828C8.33209 13.0096 8.42771 13.1333 8.55867 13.1333H10.1806C10.3096 13.1333 10.4048 13.2536 10.3753 13.3791L9.53961 16.9308C9.49259 17.1307 9.74116 17.2632 9.88085 17.1127L15.9877 10.5361C16.1066 10.4081 16.0158 10.2 15.8412 10.2H13.356C13.2258 10.2 13.1304 10.0777 13.1619 9.95149L13.8377 7.24851Z"
            fill="currentColor"/>
    </svg>;

}

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function ComponentIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ QuickIcon } { ...props }></Icon>;
}
