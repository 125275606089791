import i18n, { InitOptions, Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en-us.ts';

const getResource = (): Resource => {
    return {
        en: {
            translation: en
        }
    };
};
const option: InitOptions = {
    resources: getResource(),
    lng: 'en',
    interpolation: {
        escapeValue: false
    }
};
i18n.use(initReactI18next)
    .init(option);

export default i18n;
