import { Image, Spin } from "antd";
import MessageState from "./MessageState";
import { formattedTime } from "@chat/shared";
import videoPlay from "@/assets/svg/video-play.svg";
import { useState } from "react";
import { useAppDispatch } from "@/store";
import { showVideoModal } from "@/store/slice/modal.ts";
import { ZIMVideoMessage, MessageStatus } from "@chat/zim-sdk";

export const VideoIcon = (props: any) => {
    const { className } = props;
    return <div className={ className }>
        <svg viewBox="0 0 16 17" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.6667 4.86133L12 7.11133V9.27799L14.6667 11.528V4.86133Z"
                  fill="white"/>
            <path d="M11.9998 12.8617V3.52832H1.33301L1.33313 12.8617H11.9998Z" stroke="white" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </div>;


};
type VideoMessageProps = {
    data: ZIMVideoMessage
}
const LoadingBall = () => {
    return <Image src={ videoPlay } rootClassName="video-loading-ball" preview={ false }/>;
};
export default function VideoMessage(props: VideoMessageProps) {
    const { data } = props;
    const dispatch = useAppDispatch();
    const imageHeight = data.videoFirstFrameHeight;
    const imageWidth = data.videoFirstFrameWidth;
    const snapshotMaxHeight = 351;
    const snapshotMaxWidth = 267;
    const minHeight = Math.min(snapshotMaxWidth, imageWidth) * (imageHeight / imageWidth);
    const adaptedHeight = Math.min(minHeight, snapshotMaxHeight);
    const adaptedWidth = Math.min(imageWidth, snapshotMaxWidth);
    const viewStyle = {
        width: adaptedWidth,
        height: adaptedHeight,
    };
    const isSending = data.sentStatus === MessageStatus.Sending;
    const gotoVideo = () => {
        if (isSending || !loaded) return;
        dispatch(showVideoModal(data));
    };
    const [loaded, setLoaded] = useState(false);
    return <Spin spinning={ !loaded || isSending }>
        <div className="video-message">
            <Image onClick={ gotoVideo } style={ viewStyle }
                   preview={ false }
                   src={ data.videoFirstFrameDownloadUrl }
                   className="video-message-snap"
                   onLoad={ () => setLoaded(true) }
                   placeholder={ <div style={ viewStyle } className="common-placeholder"/> }/>
            {
                !isSending && loaded && <LoadingBall></LoadingBall>
            }
            <div className="video-message-shadow">
                <div className="video-message-duration">
                    <VideoIcon className="video-message-icon"></VideoIcon>
                    <span>{ formattedTime(data.videoDuration * 1000).join(":") }</span>
                </div>
                <MessageState className="video-message-time" data={ data }/>
            </div>
        </div>

    </Spin>;
}
