import cls from "./userStatusSetting.module.scss";
import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "@/store";
import { setImUserOnline } from "@/store/slice/common.ts";
import { UserStatusValue } from "@/utils";


const list = [
    {
        label: "Online",
        value: UserStatusValue.ON_LINE
    },
    {
        label: "Busy",
        value: UserStatusValue.BUSY
    },
    {
        label: "Away",
        value: UserStatusValue.AWAY
    },
    {
        label: "Offline",
        value: UserStatusValue.OFF_LINE
    }
];

function UserStatusSetting() {
    const { userInfo } = useAppSelector(state => state.common);
    const dispatch = useAppDispatch();
    const handleChangeState = (status: UserStatusValue) => {
        if (userInfo?.onlineStatus === status) {
            return;
        }
        dispatch(setImUserOnline(status));
    };
    return <div className={ cls.container }>
        {
            list.map(item => {
                return <div
                    className={ classnames(cls.item, userInfo?.onlineStatus === item.value && cls[`item__active-${ item.value }`]) }
                    key={ item.label }
                    onClick={ handleChangeState.bind(null, item.value) }
                >
                    <div className={ cls.item__text }>{ item.label }</div>
                </div>;
            })
        }
    </div>;
}

export default UserStatusSetting;
