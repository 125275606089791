import Icon from "@ant-design/icons";
import { GetProps } from "antd";

const UserSvg = () =>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99966 6.61895C9.51448 6.61895 10.7425 5.39095 10.7425 3.87613C10.7425 2.36131 9.51448 1.1333 7.99966 1.1333C6.48484 1.1333 5.25684 2.36131 5.25684 3.87613C5.25684 5.39095 6.48484 6.61895 7.99966 6.61895Z" stroke="#848FA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.45717 11.9741C2.42546 9.35738 5.06685 7.92627 7.99972 7.92627C10.9326 7.92627 13.574 9.35738 14.5423 11.9741C14.8402 12.7793 14.655 13.5656 14.2228 14.1619C13.8005 14.7446 13.14 15.1593 12.4149 15.3205C9.50693 15.9667 6.49251 15.9667 3.5845 15.3205C2.85941 15.1593 2.19891 14.7446 1.77669 14.1619C1.34449 13.5656 1.1592 12.7793 1.45717 11.9741ZM7.99972 9.42627C5.51787 9.42627 3.55891 10.6166 2.86395 12.4947C2.76334 12.7665 2.8108 13.0327 2.99127 13.2817C3.18172 13.5445 3.51383 13.7682 3.90989 13.8562C6.60359 14.4548 9.39585 14.4548 12.0896 13.8562C12.4856 13.7682 12.8177 13.5445 13.0082 13.2817C13.1886 13.0327 13.2361 12.7665 13.1355 12.4947C12.4405 10.6166 10.4816 9.42627 7.99972 9.42627Z" fill="#848FA0"/>
    </svg>

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function UserIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ UserSvg } { ...props }></Icon>;
}
