import cls from "@/pages/Message/feature/Conversation/ImageTool/imageTool.module.scss";
import { EmojiDataList } from "@chat/shared";
import { ExpressType } from "@/pages/Message/feature/Conversation/ImageTool/type.ts";

type EmojiProps = {
    chooseItem: (type: ExpressType, value: string) => void
}

function Emoji(props: EmojiProps) {
    const { chooseItem } = props;
    return <div className={ cls.emojiBox }>
        {
            EmojiDataList.map(item => {
                return <div className={ cls.emojiItem }
                            key={item.id}
                            onClick={ () => chooseItem(ExpressType.EMOJI, item.context) }>
                    {
                        item.context
                    }
                </div>;
            })
        }
    </div>;
}
export default Emoji;
