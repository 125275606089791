import React from "react";

interface SwitchProps extends React.HTMLAttributes<HTMLDivElement> {
    value: boolean;
}

export default function Switch(props: SwitchProps) {
    const { value, ...attrs } = props;
    const matrixOff = "0 0 0 0 0.265411 0 0 0 0 0.265411 0 0 0 0 0.265411 0 0 0 0.04 0";
    const matrixOpen = "0 0 0 0 0 0 0 0 0 0.0456798 0 0 0 0 0.285499 0 0 0 0.7 0";
    return <div { ...attrs }>
        <svg viewBox="0 0 40 24" fill="none">
            <g filter="url(#filter0_i_2210_226)">
                <rect width="40" height="24" rx="12" fill={ value ? "#0085FF" : "#DBDBDB" }/>
            </g>
            <circle cx={ value ? 28 : 12 } cy="12" r="6.5" fill="#DBDBDB" stroke="white" strokeWidth="3"/>
            <defs>
                <filter id="filter0_i_2210_226" x="0" y="0" width="40" height="24" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values={ value ? matrixOpen : matrixOff }/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2210_226"/>
                </filter>
            </defs>
        </svg>
        ;
    </div>;


}
