import Content from "./Content";
import Header from "./Header";
import Footer from "./Footer";
import { Layout } from "antd";
import Quote from "@/pages/Message/feature/Conversation/Quote.tsx";
import VideoModal from "@/pages/Message/feature/Conversation/Modal/VideoModal.tsx";
import ImageTool from "./ImageTool"

export default function Conversation() {
    return <Layout className="conversation">
        <Header/>
        <Content/>
        <ImageTool/>
        <Quote/>
        <Footer/>
        <VideoModal/>
    </Layout>;
}
