import { GetProps } from "antd";
import Icon from "@ant-design/icons";

function Qr() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M10 3H3V10H10V3Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M10 14H3V21H10V14Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M21 3H14V10H21V3Z" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M14.5 14V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        <path d="M20.5 14V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    </svg>
}
type CustomIconComponentProps = GetProps<typeof Icon>;

export default function ComponentIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ Qr } { ...props }></Icon>;
}
