import { toggleToolVisible } from "@/store/slice/message.ts";
import { useAppDispatch } from "@/store";
import { Face as FaceIcon } from "@/components/icon";

function Face() {
    const dispatch = useAppDispatch();
    const handlerClick = () => {
        dispatch(toggleToolVisible());
    };
    return <FaceIcon className="tools-item"
                     onClick={ handlerClick }
    ></FaceIcon>;
}

export default Face;
