import { useUserInfo } from "@/hooks/useUserInfo";
import { ZIMMessage } from "@chat/zim-sdk";
import photoIcon from "@/assets/svg/photo.svg";
import { Flex } from "antd";

type PicProps = {
    message: ZIMMessage
};

export default function Pic(props: PicProps) {
    const { senderUserID } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return  <Flex gap={ 4 } align="center">
        { userInfo?.userName }:
        <img src={ photoIcon } className="item-text-image" alt=""/>
        Photo
    </Flex>;
}
