import { clearIMProfile, getIMProfile, initStore } from "@/utils";
import { useNavigate } from "react-router-dom";
import { login, addEvent, offEvent } from "@chat/zim-sdk";
import { useEffect } from "react";
import { useAppDispatch } from "@/store";
import { updateLogining } from "@/store/slice/common.ts";
import { getImToken } from "@/api/im";
import store2 from "store2";
import { TOKEN_TYPE } from "@/api/http.ts";
import { message } from "antd";

export default function useGlobalEvent() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    async function tryLogin() {
        // 设置当前登录中
        dispatch(updateLogining(true));
        const { imToken, imUserID } = getIMProfile();
        try {
            const userInfo = await login(imUserID, imToken);
            if (userInfo) {
                initStore();
            } else {
                clearIMProfile();
                navigate("/login", {
                    replace: true
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(updateLogining(false));
        }
    }

    function loginCheck() {
        const { imToken, imUserID } = getIMProfile();
        if (!imToken || !imUserID) {
            clearIMProfile();
            navigate("/login", { replace: true });
        } else {
            tryLogin();
        }
    }

    const setIMListener = () => {
        addEvent({
            getImToken,
            afterRefreshToken(token) {
                store2.set(TOKEN_TYPE.CHAT, token);
            },
            kickedOut: () => {
                message.error("You have been kicked out of the chat room").then(() => {
                    clearIMProfile();
                    navigate("/login", { replace: true})
                })
             }
        });
    };
    const disposeImListener = () => {
        offEvent();
    };

    useEffect(() => {
        loginCheck();
        setIMListener();
        return () => {
            disposeImListener();
        };
    }, []);
}
