import { useParams } from "react-router-dom";
import Person from "@/pages/Message/feature/Setting/Person";
import Group from "@/pages/Message/feature/Setting/Group";
import {ConversationType} from "@chat/zim-sdk"

function Setting() {
    const { conversationType = ConversationType.Peer } = useParams();
    switch (+conversationType) {
        case ConversationType.Peer:
            return <Person/>;
        default:
            return <Group/>;
    }
}

export default Setting;
