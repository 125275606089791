const Edit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M5.1428 12.4336L12.9999 4.57645L9.85708 1.43359L1.99994 9.29074V12.4336H5.1428Z"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.999939 15.5H14.9999"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
const Delete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M3 3.8335V15.1668H13V3.8335H3Z"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M6.66669 7.1665V11.4998"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33331 7.1665V11.4998"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33331 3.8335H14.6666"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33331 3.8335L6.42965 1.8335H9.59235L10.6666 3.8335H5.33331Z"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
const Sort = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M1.5 3.5H14.5"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 8.5H14.5"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 13.5H14.5"
      stroke="#848FA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Add = () => (
  <svg
    width="8"
    height="9"
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 1000007686">
      <rect
        id="Rectangle 23425"
        y="3.5"
        width="8"
        height="1.5"
        rx="0.75"
        fill="#0085FF"
      />
      <rect
        id="Rectangle 23426"
        x="3.25"
        y="8.5"
        width="8"
        height="1.5"
        rx="0.75"
        transform="rotate(-90 3.25 8.5)"
        fill="#0085FF"
      />
    </g>
  </svg>
);
const AddUpload = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 1000006383">
      <rect y="0.5" width="24" height="24" rx="12" fill="#0085FF" />
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.968 16.0682C10.968 16.6204 11.4157 17.0682 11.968 17.0682C12.5203 17.0682 12.968 16.6204 12.968 16.0682V13.4681H15.5682C16.1204 13.4681 16.5682 13.0204 16.5682 12.4681C16.5682 11.9158 16.1204 11.4681 15.5682 11.4681H12.968V8.86816C12.968 8.31588 12.5203 7.86816 11.968 7.86816C11.4157 7.86816 10.968 8.31588 10.968 8.86816V11.4681H8.36816C7.81588 11.4681 7.36816 11.9158 7.36816 12.4681C7.36816 13.0204 7.81588 13.4681 8.36816 13.4681H10.968V16.0682Z"
        fill="white"
      />
    </g>
  </svg>
);

export default {
  Edit,
  Delete,
  Sort,
  Add,
  AddUpload,
};
