import { Flex, Layout, Spin } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import _ from "lodash";
import {
    // FriendIcon,
    // GroupIcon,
    // ImageIcon,
    // ReviewIcon,
    MessageIcon,
    // SettingIcon
} from "@/components/icon";
import classnames from "classnames";
import useGlobalEvent from "@/hooks/useGlobalEvent.ts";
import { useAppSelector } from "@/store";
import UserAvatar from "@/components/layout/UserAvatar.tsx";
import "./layout.scss";
import NetWork from "@/components/layout/NetWork.tsx";
import UserStatusSetting from "@/components/layout/UserStatusSetting.tsx";

const { Sider, Content } = Layout;

function AppLayout() {
    const { t } = useTranslation();
    const { isLogining } = useAppSelector(state => state.common);
    const [tabsList] = useState([
        {
            key: "/chat/message",
            icon: MessageIcon,
            label: _.capitalize(t("common.message"))
        },
        // {
        //     key: "/chat/group",
        //     icon: GroupIcon,
        //     label: _.capitalize(t("common.group"))
        // },
        // {
        //     key: "/chat/friend",
        //     icon: FriendIcon,
        //     label: _.capitalize(t("common.friend"))
        // },
        // {
        //     key: "/chat/image",
        //     icon: ImageIcon,
        //     label: _.capitalize(t("common.image"))
        // },
        // {
        //     key: "/chat/review",
        //     icon: ReviewIcon,
        //     label: _.capitalize(t("common.review"))
        // },
        // {
        //     key: "/chat/setting",
        //     icon: SettingIcon,
        //     label: _.capitalize(t("common.setting"))
        // }
    ]);
    const navigate = useNavigate();
    const location = useLocation();
    const handleClick = (path: string) => {
        if (_.startsWith(location.pathname, path)) return;
        navigate(path);
    };
    useGlobalEvent();
    return <Spin spinning={ isLogining }>
        <Layout className="app-layout">
            <Sider width="100px" style={ { height: '100vh' } }>
                <div className="app-layout-sider">
                    <Flex justify="center" style={ { margin: "20px 0" } }>
                        <UserAvatar/>
                    </Flex>
                    <div className="sider-tabs">
                        {
                            tabsList.map(item => {
                                return <Flex
                                    key={ item.key }
                                    onClick={ () => handleClick(item.key) }
                                    className={ classnames("sider-tabs-item", { "sider-tabs-item__active": _.startsWith(location.pathname, item.key) }) }
                                    vertical align="center" justify="center">
                                    <item.icon className="sider-tabs-item-icon"></item.icon>
                                    <div className="sider-tabs-item-text">{ item.label }</div>
                                </Flex>;
                            })
                        }
                    </div>
                    <UserStatusSetting/>
                    <NetWork/>
                </div>

            </Sider>
            <Content className="app-layout-content">
                <Outlet/>
            </Content>
        </Layout>
    </Spin>;
}

export default AppLayout;
