import Sider from "@/pages/Message/feature/Sider";
import { Layout } from "antd";
import { Outlet, useParams } from "react-router-dom";
import { useAppSelector } from "@/store";
import "./chat.scss";
import Setting from "@/pages/Message/feature/Setting";

function Chat() {
    const { chatSettingVisible } = useAppSelector(state => state.message);
    const { conversationID, conversationType } = useParams();

    return <Layout className="chat-page">
        <Layout.Sider width={ 280 }>
            <Sider></Sider>
        </Layout.Sider>
        <Layout.Content>
            <Outlet/>
        </Layout.Content>
        {
            conversationID && conversationType &&
            <Layout.Sider collapsedWidth={ 0 } collapsed={ !chatSettingVisible } width={ 248 }
                          style={ { background: "#fff" } }>
                <Setting/>
            </Layout.Sider>
        }

    </Layout>;
}

export default Chat;
