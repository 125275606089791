import { Alert, Image } from "antd";
import noticeIcon from "@/assets/svg/notice.svg";
import { useParams } from "react-router-dom";
import { useAppSelector } from "@/store";
import Marquee from "react-fast-marquee";
import { ConversationType } from "@chat/zim-sdk";

function Notice() {
    const params = useParams();
    const group = useAppSelector(state => state.message.currentGroup);
    const { conversationType } = params;
    if (!conversationType || +conversationType == ConversationType.Peer || !group) return null;
    if (!group.notification.trim()) return null;
    return <Alert
        type="info"
        banner
        showIcon
        icon={ <div className="notice-icon">
            <Image src={ noticeIcon } preview={ false }/>
        </div> }
        closable
        // closeIcon={ <CloseIcon size={ 24 } className="close-icon"/> }
        message={
            <Marquee className="notice-text" autoFill={false} gradient={ true }>group chat
                announcement: { group.notification }</Marquee>
        }
        className="conversation-content-notice"
    />;
}

export default Notice;
