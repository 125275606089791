import React, { FC, useRef, useState } from "react";
import { Modal, Popover, Button, Image } from "antd";
import Icons from "./Icons";
import ActionContent from "./ActionContent";
import {
  ReplyType,
  addQuickList,
  delQuickList,
  updateQuickList,
  UPIType,
  addUPIList,
} from "@/api/im";
import cls from "./tool.module.scss";

type DynmicListProps<T> = {
  list: T[];
  onChangeList: (list: T[]) => void;
  type: string;
  getQuickReplys?: (callback?: (list: ReplyType[]) => void) => void;
  getUPIs?: (callback?: (list: ReplyType[]) => void) => void;
};

const ADD_TEXTS: any = {
  quickReply: "Add common phrases",
  UPI: "Add UPI",
};

const MODAL_TITLES: any = {
  quickReply: {
    add: "Add common phrases",
    edit: "Edit common phrases",
  },
  UPI: {
    add: "Add UPI",
    edit: "Edit UPI",
  },
};

const DynmicList: FC<DynmicListProps<ReplyType>> = ({
  list = [],
  type,
  onChangeList,
  getQuickReplys,
  getUPIs,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [draggedId, setDraggedId] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [delPopoverOpen, setDelPopoverOpen] = useState(
    Array(list.length).fill(false)
  );
  const [isOver, setIsOver] = useState(Array(list.length).fill(false));
  const [action, setAction] = useState<"add" | "edit">("add");
  const [reply, setReply] = useState<ReplyType>({
    content: "",
    contentType: 1,
    sort: list.length + 1,
  });
  const [UPI, setUPI] = useState<ReplyType>({
    content: "",
    otherUrl: "",
    contentType: 2 ,
    sort: list.length + 1,
  });
  const toggleDelPopoverOpen = (index: number, shouldOpen: boolean) => {
    setDelPopoverOpen((prev) => {
      const newArray = [...prev];
      newArray[index] = shouldOpen;
      return newArray;
    });
  };
  const handleDragStart = (id: string) => {
    setDraggedId(id);
    if (listRef.current) {
      listRef.current.style.overflowY = "hidden";
    }
  };
  const handleDragOver = (event: React.DragEvent, index: number) => {
    event.preventDefault();
    const overIndex = list.findIndex((item) => item.id === draggedId);
    if (!isOver[index] && overIndex !== index) {
      setIsOver((prevIsOver) => ({ ...prevIsOver, [index]: true }));
    }
    // if (overIndex !== index.ts) {
    //   setIsOver((prevIsOver) => ({
    //     ...prevIsOver,
    //     // [overIndex]: false,
    //     [index.ts]: true,
    //   }));
    // }
  };
  const handleDragLeave = (event: React.DragEvent, index: number) => {
    event.preventDefault();
    setIsOver((prevIsOver) => ({ ...prevIsOver, [index]: false }));
  };
  const handleDrop = (event: React.DragEvent, index: number) => {
    event.preventDefault();
    setIsOver(Array(list.length).fill(false));
    const draggedItem = list.find((item) => item.id === draggedId);
    const newList = list.filter((item) => item.id !== draggedId);
    if (draggedItem) {
      newList.splice(index, 0, draggedItem);
    }
    onChangeList(newList.map((item, index) => ({ ...item, sort: index + 1 })));
  };
  const handleDragEnd = () => {
    if (listRef.current) {
      listRef.current.style.overflowY = "auto";
    }
  };
  const initReply = (list: ReplyType[]) => {
    setReply({
      content: "",
      contentType: 1,
      sort: list.length + 1,
    });
  };
  const initUPI = (list: ReplyType[]) => {
    setUPI({
      content: "",
      contentType: 2,
      otherUrl: "",
      sort: list.length + 1,
    });
  };
  const initDelPopoverOpen = (list: (ReplyType | UPIType)[]) => {
    setDelPopoverOpen(Array(list.length).fill(false));
  };
  const addAction = () => {
    initReply(list);
    initUPI(list);
    setAction("add");
    setVisible(true);
  };
  const editAction = (item: ReplyType) => {
    if (type === "UPI") {
      setUPI(item);
    } else {
      setReply(item);
    }
    setAction("edit");
    setVisible(true);
  };
  const onAdd = () => {
    const [request, params, propMethd, callback] =
      type === "quickReply"
        ? [addQuickList, reply, getQuickReplys, initReply]
        : [addUPIList, UPI, getUPIs, initUPI];
    request(params as any).then(() => {
      // @ts-ignore
      propMethd?.(callback);
      setVisible(false);
    });
  };
  const onEdit = () => {
    const [request, params, propMethd, callback] =
      type === "quickReply"
        ? [updateQuickList, reply, getQuickReplys, initReply]
        : [updateQuickList, UPI, getUPIs, initUPI];
    // UPDATE TODO
    const findIndex = list.findIndex((item) => item.id === params.id);
    const newList = list.map((item, i) => {
      return i === findIndex ? params : item;
    });
    request(newList).then(() => {
      // @ts-ignore
      propMethd?.(callback);
      setVisible(false);
    });
    // if (type === "quickReply") {
    //   const findIndex = list.findIndex((item) => item.id === reply.id);
    //   const newList = list.map((item, i) => {
    //     return i === findIndex ? reply : item;
    //   });
    //   updateQuickList(newList).then((res) => {
    //     getQuickReplys?.(initReply);
    //     console.log(`%c${JSON.stringify(res)}`, "font-size: 64px; color: red");
    //     setVisible(false);
    //   });
    // } else {
    //   const findIndex = list.findIndex((item) => item.id === UPI.id);
    //   const newList = list.map((item, i) => {
    //     return i === findIndex ? UPI : item;
    //   });
    //   updateUPIList(newList).then((res) => {
    //     getUPIs?.(initUPI);
    //     console.log(`%c${JSON.stringify(res)}`, "font-size: 64px; color: red");
    //     setVisible(false);
    //   });
    // }
  };
  const onOk = () => {
    if (action === "add") {
      onAdd();
    } else if (action === "edit") {
      onEdit();
    }
  };
  const delHandler = (id: string) => {
    switch (type) {
      case "quickReply": {
        delQuickList(id).then(() => {
          getQuickReplys?.(initDelPopoverOpen);
        });
        break;
      }
      case "UPI": {
        delQuickList(id).then(() => {
          getUPIs?.(initDelPopoverOpen);
        });
        break;
      }
    }
  };

  return (
    <>
      <div className={cls.dynmicList__container}>
        <ul
          ref={listRef}
          className={cls.quickMessage__list}
          style={{ maxHeight: 600 }}
        >
          {list.map((item, index) => (
            <li
              className={
                cls[type === "UPI" ? "UPI__inner__item" : "quickMessage__item"]
              }
              key={item.id}
              draggable
              onDragStart={() => handleDragStart(item.id!)}
              onDragOver={(event) => handleDragOver(event, index)}
              onDragLeave={(event) => handleDragLeave(event, index)}
              onDrop={(event) => handleDrop(event, index)}
              onDragEnd={handleDragEnd}
              style={{
                cursor: "move",
                userSelect: "none",
                backgroundColor: isOver[index] ? "lightblue" : "white",
              }}
            >
              {type === "quickReply" && (
                <div className={cls.quickMessage__innner__text}>
                  {item.content}
                </div>
              )}
              {type === "UPI" && (
                <div className={cls.UPI__show__UI}>
                  <div className={cls.UPI__qr__UI}>
                    <Image src={item.otherUrl} alt="qrCode" />
                  </div>
                  <div className={cls.UPI__address__text}>{item.content}</div>
                </div>
              )}
              <div className={cls.quickMessage__item__handler}>
                <div
                  className={cls.quickMessage__item__action}
                  onClick={() => editAction(item)}
                >
                  <Icons.Edit />
                </div>
                <Popover
                  open={delPopoverOpen[index]}
                  placement="leftTop"
                  content={
                    <div className={cls.quickReply__del__action}>
                      <p className={cls.quickReply__del__text}>
                        {`Are you sure you want to delete this ${type === "UPI" ? "UPI" : "common phrase"}?`}
                      </p>
                      <div className={cls.quickReply__del__btn}>
                        <Button
                          onClick={() => toggleDelPopoverOpen(index, false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          onClick={() => delHandler(item.id!)}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  }
                >
                  <div
                    className={cls.quickMessage__item__action}
                    onClick={() => toggleDelPopoverOpen(index, true)}
                  >
                    <Icons.Delete />
                  </div>
                </Popover>
                <div>
                  <Icons.Sort />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className={cls.add__tag} onClick={addAction}>
          <div className={cls.add__wrap}>
            <Icons.Add />
          </div>
          <span>{ADD_TEXTS[type]}</span>
        </div>
      </div>
      <Modal
        width={682}
        closable={false}
        onCancel={() => setVisible(false)}
        title={MODAL_TITLES[type][action]}
        open={visible}
        centered
        okText={action === "add" ? "Add" : "Save"}
        onOk={onOk}
        okButtonProps={{
          disabled:
            (type === "quickReply" &&
              (!reply.content || reply.content.length > 120)) ||
            (type === "UPI" && !(UPI.content && UPI.otherUrl)) ||
            UPI.content!.length > 100,
        }}
      >
        {type === "quickReply" ? (
          <ActionContent.QuickReply reply={reply} setReply={setReply} />
        ) : (
          <ActionContent.UPI UPI={UPI} setUPI={setUPI} />
        )}
      </Modal>
    </>
  );
};

export default DynmicList;
