import React from "react";
import classnames from "classnames";
// import { SendingIcon } from "@/components/icon";
import { getMmaTime } from "@chat/shared";
// import { useAppSelector } from "@/store";
import { ReadStatus } from "@/components/icon";
import {
    ZIMMessage,
    ConversationType,
    MessageReceiptStatus,
    MessageStatus
} from "@chat/zim-sdk";
import { useParams } from "react-router-dom";

type MessageStateProps = {
    data: ZIMMessage;
    style?: React.CSSProperties;
    className?: string
}


function MessageState(props: MessageStateProps) {
    const { data, className, style } = props;
    const { conversationType = ConversationType.Unknown } = useParams();
    // const { currentConversation } = useAppSelector(state => state.message);
    return <div className={ classnames("message-state", className) } style={ style }>
        <span>{ getMmaTime(data.timestamp) }</span>
        {
            data.sentStatus === MessageStatus.Success && +conversationType === ConversationType.Peer &&
            <ReadStatus read={ data.receiptStatus === MessageReceiptStatus.Done } className="message-icon-read"/>
        }
        {/*{*/ }
        {/*    data.status === MessageStatus.Sending && <SendingIcon className="message-icon-sending"/>*/ }
        {/*}*/ }
    </div>;
}

export default MessageState;
