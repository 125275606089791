import { ChangeEvent, useRef } from "react";
import { Input, Image, message } from "antd";
import { ReplyType, uploadFile } from "@/api/im";
import Icons from "./Icons";
import cls from "./tool.module.scss";
import { getBase64FromFile } from "@chat/shared";

const { TextArea } = Input;
type QuickReplyProps = {
  reply: ReplyType;
  setReply: (reply: ReplyType) => void;
};
type QuickUPIProps = {
  UPI: ReplyType;
  setUPI: (UPI: ReplyType) => void;
};
const QuickReply = ({ reply, setReply }: QuickReplyProps) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReply({
      ...reply,
      content: e.target.value,
    });
  };

  return (
    <TextArea
      className="textArea"
      onChange={handleChange}
      placeholder="Please enter a common phrase"
      value={reply.content}
      count={{
        show: true,
        max: 120,
      }}
      rows={10}
    />
  );
};

const UPI = ({ UPI, setUPI }: QuickUPIProps) => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const uploadClick = () => {
    uploadRef.current?.click();
  };
  const fileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (uploadRef.current) {
        // const file = uploadRef.current?.files?.[0];
        const file = e.target.files?.[0];
        e.target.value = "";
        if (file) {
          if (file.size > 1024 * 1024) {
            message.error("File size exceeds 1 MB");
            return;
          }
          const url = await getBase64FromFile(file);
          uploadFile(url).then((data: any) => {
              setUPI({
                ...UPI,
                otherUrl: data.data
              });
          }).catch(({ errCode, errMsg }) => {
              message.error(`file upload failed. ${errCode}: ${errMsg}`);
          });

        }
      }
    } catch(e) {
      console.log('file change error', e);
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUPI({
      ...UPI,
      content: e.target.value
    });
  };
  return (
    <div className={cls.UPI}>
      <div className={cls.UPI__addressQR}>
        <p className={cls.UPI__address__title}>UPI address</p>
        <div className={cls.UPI__address__input}>
          <Input count={{
        show: true,
        max: 100,
      }} onChange={handleChange} value={UPI.content} placeholder="Please enter the message for mass sending" />
        </div>
      </div>
      <div className={cls.UPI__addressQR}>
        <p className={cls.UPI__qr__title}>UPI QR code</p>
        <input
          accept="image/*"
          onChange={fileChange}
          type="file"
          ref={uploadRef}
          style={{ display: "none" }}
        />
        {UPI.otherUrl ? (
          <div className={cls.UPI__qr__wrap} >
            <div className={cls.UPI__qr__img}>
              <Image src={UPI.otherUrl} alt="qrCode" />
            </div>
            <p className={cls.UPI__reupload} onClick={uploadClick}>
              Reupload
            </p>
          </div>
        ) : (
          <div className={cls.UPI__qr__upload} onClick={uploadClick}>
            <Icons.AddUpload />
            <p className={cls.UPI__upload__text}>AddUpload</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default {
  QuickReply,
  UPI,
};
