import { CustomType, MessageType, ZIMAudioMessage, ZIMCustomMessage, ZIMMessage, ZIMVideoMessage } from "@chat/zim-sdk";
import Text from "./Text";
import Pic from "./Pic";
import Audio from "./Audio";
import Video from "./Video";
import Card from "./Card";

type LastMessageProps = {
    message: ZIMMessage;
};

function LastMessage(props: LastMessageProps) {
    const { message } = props;
    switch (message.type) {
        case MessageType.Text:
            return <Text message={ message }></Text>;
        case MessageType.Audio:
            return <Audio message={ message as ZIMAudioMessage }></Audio>;
        case MessageType.Video:
            return <Video message={ message as ZIMVideoMessage }></Video>;
        case MessageType.Custom:
            if ((message as ZIMCustomMessage).subType === CustomType.Card) {
                return <Card message={ message }></Card>;
            }
            return <Pic message={ message }></Pic>;
        default:
            return <Pic message={ message }></Pic>;
    }
}

export default LastMessage;
