import { Image, Layout, Input, message, Flex } from "antd";
// import redPacketIcon from "@/assets/message/redPacket.png";
// import giftIcon from "@/assets/message/gift.png";
import flyIcon from "@/assets/message/fly.png";
import React, { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import useSendMessage from "@/hooks/useSendMessage";
import { useAppDispatch, useAppSelector } from "@/store";
import { updateInputText } from "@/store/slice/message.ts";
import useFileMessage from "@/hooks/useFileMessage.ts";
import { isImageOrVideo } from "@chat/shared";
import Face from "@/pages/Message/feature/Conversation/Tool/Face.tsx";
import FileUpdate from "@/pages/Message/feature/Conversation/Tool/FileUpdate.tsx";
import Quick from "@/pages/Message/feature/Conversation/Tool/Quick.tsx";
import Support from "@/pages/Message/feature/Conversation/Tool/Support.tsx";
import { createTextMessage } from "@chat/zim-sdk";

const { TextArea } = Input;

export default function Footer() {
    const { sendMessage } = useSendMessage();
    const dispatch = useAppDispatch();
    const { quoteMessage, inputText: text, currentConversation } = useAppSelector(
        (state) => state.message
    );
    const [disabled, setDisabled] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);
    const { createImageOrVideoMessage } = useFileMessage();
    const toolList = [
        {
            component: Face,
            title: "Face",
        },
        {
            component: FileUpdate,
            title: "GIF",
        },
        {
            component: Quick,
            title: "QuickMessage",
            type: "quickReply"
        },
        {
            component: Support,
            title: "Support",
        },
        {
            component: Quick,
            title: "UPI",
            type: "UPI",
        },
        // {
        //     icon: redPacketIcon,
        //     title: "红包"
        // },
        // {
        //     icon: giftIcon,
        //     title: "礼物"
        // }
    ];
    const handleKeyDown = async (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessageValue();
        }
    };
    const sendMessageValue = async () => {
        if (!text.trim()) return;
        try {
            setDisabled(true);
            // 发送消息
            await sendMessage({
                message: createTextMessage(text.trim()),
                conversationID: currentConversation.conversationID,
                originalMessage: quoteMessage
            });
        } catch (e) {
            console.error(e);
        } finally {
            setDisabled(false);
        }
    };
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(updateInputText(e.target.value));
    };
    const listenFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];
        e.target.value = "";
        createImageOrVideoMessage(file).then((message) => {
            // 发送消息
            sendMessage({
                message,
                conversationID: currentConversation.conversationID
            });
        });
    };
    const handleSendFile = async (files: FileList) => {
        // 处理文件，只有图片和视频可以粘贴
        const filesList = Array.from(files).filter((item) => {
            return isImageOrVideo(item);
        });
        const messagesList = filesList.map((file) => {
            return createImageOrVideoMessage(file);
        });
        const messageResolveList = await Promise.all(messagesList);
        for (const message of messageResolveList) {
            await sendMessage({
                message,
                conversationID: currentConversation.conversationID
            });
        }
    };
    // 粘贴实现
    const handlePaste = async (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        try {
            const files = e.clipboardData.files;
            if (files.length > 0) {
                e.preventDefault();
                await handleSendFile(files);
            }
        } catch (error) {
            message.error("Paste failed");
        }
    };
    // 拖拽发送
    const handleDrop = async (e: React.DragEvent<HTMLTextAreaElement>) => {
        try {
            e.preventDefault();
            const files = e.dataTransfer.files;
            if (files.length === 0) return;
            await handleSendFile(files);
        } catch (error) {
            message.error("sending failed");
        }
    };
    return (
        <Layout.Footer className="conversation-footer">
            <Flex className="tools" gap={20}>
                { toolList.map((item) => {
                    return <item.component key={ item.title } type={ item.type as any }/>;
                }) }
            </Flex>
            <div className="textArea-container">
                <TextArea
                    className="textArea"
                    onDrop={ handleDrop }
                    disabled={ disabled }
                    onKeyDown={ handleKeyDown }
                    onChange={ handleChange }
                    onPaste={ handlePaste }
                    placeholder="Enter to send, Shift + Enter for new line."
                    value={ text }
                />
                <Image
                    src={ flyIcon }
                    preview={ false }
                    onClick={ sendMessageValue }
                    className="sendBtn"
                />
            </div>
            <input
                style={ { display: "none" } }
                onChange={ listenFileChange }
                ref={ fileRef }
                type="file"
                accept="image/*,video/*"
            />
        </Layout.Footer>
    );
}
