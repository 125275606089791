import { uid } from "uid";
// 登录平台

import { getBaseUrlById, TypeResponse } from "@/api/http.ts";

const apis = {
    login: "/password/login",
    updateInfo: "/user/updateInfo",
    userFull: "/user/find/full",
    getReqParams: "/openIm/getReqParams",
    // 获取客服
    getCustomer: "/openIm/customers",
    // 修改修改im的状态
    setCustomer: "/openIm/updateImStatus"
};
type LoginPayload = {
    password: string;
    userPhone: number;
}
export const login = (payload: LoginPayload) => {
    const http = getBaseUrlById();
    return http.post<any, TypeResponse<any>>(apis.login, {
        ...payload,
        versionCode: 999,
        channel: "h5",
        channelId: "indusbet",
        visitor: uid(32),
    });
};


// 修改用户名
export const setUserName = (userName: string) => {
    const http = getBaseUrlById();
    return http.post<any, TypeResponse<any>>(`${ apis.updateInfo }?userName=${ userName }`, null);
};
