import { MessageItemProps } from "./type";
import { Image } from "antd";
import MessageState from "@/pages/Message/feature/Conversation/MessageItem/MessageState.tsx";
import { ZIMCustomMessage, ServiceCardItem } from "@chat/zim-sdk";

function CardMessage(props: MessageItemProps<ZIMCustomMessage>) {
    const { data } = props;
    const message: ServiceCardItem =JSON.parse(data.message);

    return <div className="card-message">
        <div className="card-content">
            <Image src={ message.faceURL } className="card-avatar" preview={ false }/>
            <div className="card-right">
                <div className="card-nickname">{ message.nickname }</div>
                <div className="card-description">Customer Service Business Card</div>
            </div>
        </div>
        <MessageState data={ props.data } className="message-state__bottom" style={ { padding: 0 } }/>
    </div>;
}

export default CardMessage;
