import { createHttp, TypeResponse, TOKEN_TYPE } from "@/api/http";
import { UserStatusValue } from "@/utils";

const http = createHttp(
    import.meta.env.VITE_APP_IM_API_URL!,
    TOKEN_TYPE.ORIGIN
);
const apis = {
    getReqParams: "/zgIm/getReqParams",
    // 获取客服
    getCustomer: "/zgIm/customers",
    // 修改修改im的状态
    setCustomer: "/zgIm/updateImStatus",
    // 获取gif
    getGif: "/zgIm/getImEmoticons",
    // 分页获取gif
    getGifPage: "/zgIm/getImEmoticonsPage",
    // 获取quickReply 快捷回复
    // 快捷回复获取列表
    quickList: "/quickReply/getList",
    // 快捷回复保存
    quickSave: "/quickReply/save",
    // 快捷回复编辑
    quickEdit: "/quickReply/edit",
    // 批量修改
    quickEditList: "/quickReply/editList",
    // 快捷回复删除
    quickDelete: "/quickReply/drop",
    // 上传文件
    uploadFile: "/quickReply/uploadFileV2",
};


// 获取gif图片
export const getGifImage = () => {
    return http.get<any, TypeResponse<any>>(apis.getGif);
};
// 分页获取
export const getGifPage = (params: { pageNo: number, pageSize: number }) => {
    console.log(params, "getGifPage");
    return http.post<any, TypeResponse<any>>(apis.getGifPage, params);
};

export type ReplyType = {
    content?: string, // 内容
    sort?: number,
    remark?: string, // 备注
    otherUrl?: string,
    contentType?: 1 | 2, // 1话术 2UPI
    id?: string
};
export const getQuickList = (type: 1 | 2) => {
    return http.post<null, TypeResponse<{ content: ReplyType[] }>>(apis.quickList, {
        status: 1,
        contentType: type,
        pageNo: 1,
        pageSize: 1000
    });
};
export const addQuickList = (replay: ReplyType) => {
    return http.post<ReplyType, TypeResponse<any>>(apis.quickSave, replay);
};
export const delQuickList = (id: string | number) => {
    return http.post<ReplyType, TypeResponse<any>>(`${ apis.quickDelete }/${ id }`);
};
export const updateQuickList = (editList: ReplyType[]) => {
    return http.post<ReplyType[], TypeResponse<any>>(apis.quickEditList, {
        editList,
    });
};
export const updateQuick = (data: ReplyType) => {
    return http.post(apis.quickEdit, data);
};
export const uploadFile = (data: string) => {
    return http.post(apis.uploadFile, {data});
};

export type UPIType = {
    id?: number;
    address: string;
    qrCode: string;
    sort: number;
    content?: string;
};
export const getUPIList = () => {
    return http.post<null, TypeResponse<ReplyType[]>>(apis.quickList, {
        status: 1,
        contentType: 2,
        pageNo: 1,
        pageSize: 1000
    });
};
export const addUPIList = (UPI: ReplyType) => {
    return http.post<ReplyType, TypeResponse<any>>(apis.quickSave, UPI);
};
export const delUPIList = (id: number) => {
    return http.post<ReplyType, TypeResponse<any>>(`${ apis.quickDelete }/${ id }`);
};

export enum CUSTOM_TYPE {
    RECHARGE = "1",
    GAME = "2",
}

export type IdentityItem = {
    imCustomer: CUSTOM_TYPE;
    imUserId: string;
    imUserStatus: UserStatusValue; //   * 0:离线 1:在线，2:忙碌，3:暂离，
    userAvatar: string;
    userId: number;
    userName: string;
}
export const getCustomer = () => {
    return http.get<any, TypeResponse<IdentityItem[]>>(apis.getCustomer);
};


export const setCustomer = (imUserStatus: UserStatusValue) => {
    // imCustomer 可选 1支付客服 2游戏客服
    return http.get<any, TypeResponse<any>>(apis.setCustomer, {
        params: {
            imUserStatus
        }
    });
};

// 新接口
type ImTokenRes = {
    userID: string;
    token: string;
    imCustomer: CUSTOM_TYPE, //    * im客服 1支付客服 2游戏客服
    imUserStatus: UserStatusValue, //     * 0:离线 1:在线，2:忙碌，3:暂离，
}
// // 获取imtoken
export const getImToken = () => {
    return http.post<any, TypeResponse<ImTokenRes>>(apis.getReqParams, null);
};

