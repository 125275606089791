import { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { formattedTime, addZero } from "@chat/shared";
import { useAppDispatch, useAppSelector } from "@/store";
import { setCurrentVoice } from "@/store/slice/message";
import { config } from "@chat/shared";
import MessageState from "./MessageState";
import { Image } from "antd";
import { StartPlay, StopPlay, VoiceSmall } from "@/components/icon";
import useSelfMessage from "./useSelfMessage";
import classnames from "classnames";
import { ZIMAudioMessage } from "@chat/zim-sdk";
import { useUserInfo } from "@/hooks/useUserInfo.ts";

type VoiceMessageProps = {
    data: ZIMAudioMessage
}
export default function VoiceMessage(props: VoiceMessageProps) {
    const { data } = props;
    const {userInfo} = useUserInfo(data.senderUserID)
    const dispatch = useAppDispatch();
    const isSelf = useSelfMessage(data);
    const currentVoiceClientMsgID = useAppSelector(state => state.message.currentVoice);
    const waveRef = useRef<any>();
    const [time, setTime] = useState<(number | string)[]>([0, 0]);
    const waveSurferRef = useRef<WaveSurfer>();
    const [isPlay, setIsPlay] = useState(false);
    const handlerPlay = () => {
        dispatch(setCurrentVoice(data.messageID));
        setIsPlay(!isPlay);
        waveSurferRef.current?.playPause();
    };
    const initWave = async () => {
        const waveDom = waveRef.current;
        const waveSurfer = WaveSurfer.create({
            ...config.wave,
            container: waveDom,
            // url: data.soundElem.sourceUrl,
            url: data.fileDownloadUrl,
            duration: data.audioDuration,
            height: waveDom.offsetHeight
        });
        waveSurfer.on("decode", () => {
            const duration = formattedTime(Math.ceil(waveSurfer.getDuration()) * 1000, false);
            const [t1, t2] = duration;
            setTime([t1, addZero(t2)]);

        });
        // 不能相信finish的回调， 加一个进度监听用来关闭
        waveSurfer.on("audioprocess", (percent) => {
            if (percent >= data.audioDuration) {
                setIsPlay(false);
                waveSurfer.setTime(0);
                waveSurfer.stop();
            }
        });
        waveSurfer.on("finish", () => {
            setIsPlay(false);
            waveSurfer.setTime(0);
        });
        waveSurferRef.current = waveSurfer;
    };
    useEffect(() => {
        initWave();
    }, []);
    useEffect(() => {
        if (currentVoiceClientMsgID !== data.messageID) {
            // 关闭音频，防止多个音频出现
            waveSurferRef.current?.pause();
            setIsPlay(false);
        }
    }, [currentVoiceClientMsgID]);
    return <div className="voice-message">
        <div className="voice-avatar">
            <Image className="voice-avatar-image" preview={ false } src={ userInfo?.userAvatarUrl }></Image>
            <VoiceSmall self={ isSelf } className="voice-avatar-icon"></VoiceSmall>
        </div>
        <div className="voice-message-content">
            <div className={ "voice-message-top" }>
                {
                    isPlay ? <StopPlay className="voice-message-content-handler" onClick={ handlerPlay }/> :
                        <StartPlay className="voice-message-content-handler" onClick={ handlerPlay }/>
                }
                <div className={ classnames("message-content-wave", "voice-wave", { "voice-wave-play": isPlay }) }
                     ref={ waveRef }></div>

            </div>
            <div className="voice-message-content-during">
                <span>{ `${ time.join(":") }` }</span>
                <MessageState data={ data }/>
            </div>
        </div>
    </div>;
}
