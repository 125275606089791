import Icon from "@ant-design/icons";
import { GetProps } from "antd";

const CloseSvg = () => <svg width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.5952 7.35951C19.135 6.81973 19.135 5.94457 18.5952 5.4048C18.0554 4.86502 17.1802 4.86504 16.6405 5.40482L12 10.0454L7.3596 5.40482C6.81983 4.86504 5.94467 4.86502 5.40489 5.4048C4.8651 5.94457 4.86509 6.81973 5.40487 7.35951L10.0453 12.0001L5.40487 16.6407C4.86509 17.1805 4.8651 18.0556 5.40489 18.5954C5.94467 19.1352 6.81983 19.1352 7.3596 18.5954L12 13.9548L16.6405 18.5954C17.1802 19.1352 18.0554 19.1352 18.5952 18.5954C19.135 18.0556 19.135 17.1805 18.5952 16.6407L13.9547 12.0001L18.5952 7.35951Z"
              fill="#191C1F"/>
    </svg>

;
type CustomIconComponentProps = GetProps<typeof Icon>;

export default function PasswordIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ CloseSvg } { ...props }></Icon>;
}
