import cls from "@/pages/Message/feature/Setting/setting.module.scss";
import { Avatar, Button, Image, Modal, Space, Spin, Tooltip, Typography } from "antd";
import closeIcon from "@/assets/svg/close.svg";
import { copyableProps } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    setChatSettingVisible,
    // setMessageList
} from "@/store/slice/message.ts";
import { replaceOssImage } from "@chat/shared";
import rightOutLine from "@/assets/svg/rightOutLined.svg";
import { useEffect, useState } from "react";
import Switch from "@/components/Switch";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { GroupMemberRole } from "@chat/zim-sdk";

const { Paragraph } = Typography;
// type MemberParmas = {
//     groupID: string;
//     offset: number,
//     filter: number,
//     count: number
// }
const { confirm } = Modal;
export default function Group() {
    const {
        currentGroup,
        // currentConversation,
        selfInGroupInfo,
        currentSettingLoading
    } = useAppSelector(state => state.message);
    const userInfo = useAppSelector(state => state.common.userInfo);
    const dispatch = useAppDispatch();
    const handleClose = () => {
        dispatch(setChatSettingVisible(false));
    };
    // 群管理员禁言
    const muteGroup = () => {
        if (!currentGroup) return;
        // IMSDK.changeGroupMute({
        //     groupID: currentGroup.groupID,
        //     isMute: !(currentGroup.status === GroupStatus.Muted)
        // });
    };
    const [personList, setPersonList] = useState<any[]>([]);
    const [adminList, setAdminnList] = useState<any[]>([]);
    const getMemberList = async () => {
        if (!currentGroup?.groupID) return [];
        // const { count = 18, offset = 0, filter = 0 } = parmas;
        // const obj = {
        //     groupID: currentGroup?.groupID,
        //     offset,
        //     filter,
        //     count
        // };
        return []
        // return (await IMSDK.getGroupMemberList(obj as any)).data;
    };
    const clearHistory = async () => {
        confirm({
            width: 440,
            title: "Do you want to delete conversation history?",
            icon: <ExclamationCircleFilled/>,
            content: "The history of the current conversation will be deleted",
            async onOk() {
                // const res = await IMSDK.clearConversationAndDeleteAllMsg(currentConversation.conversationID);
                // if (res.errCode === 0) {
                //     dispatch(setMessageList([]));
                // }
            },
        });
    };
    useEffect(() => {
        if (currentGroup && userInfo) {
            getMemberList().then(res => setPersonList(res || []));
            getMemberList().then(res => setAdminnList(res || []));
        }

    }, [currentGroup, userInfo]);
    return <div className={ cls.setting }>
        <div className={ cls.header }>
            {/*<Col>*/ }
            {/*    <Image src={ arrowIcon } preview={ false }/>*/ }
            {/*</Col>*/ }
            <div className={ cls.header__text }>
                Group chat settings
            </div>
            <Image wrapperClassName={ cls.header__close } onClick={ handleClose } src={ closeIcon } preview={ false }/>
        </div>
        <Spin spinning={ currentSettingLoading }>
            <div className={ cls.userInfo }>
                <div className={ cls.item }>
                    <div className={ cls.label }>Group chat ID</div>
                    <Paragraph className={ cls.value }
                               copyable={ copyableProps(currentGroup?.groupID || "") }>{ currentGroup?.groupID }</Paragraph>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label }>Group name</div>
                    <div className={ cls.value }>{ currentGroup?.groupName }</div>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label }>Group avatar</div>
                    <div className={ cls.value }>
                        <Avatar size={ 40 } src={ replaceOssImage(currentGroup?.faceURL) }/>
                    </div>
                </div>
                <div className={ cls.item }>
                    <div className={ cls.label } style={ { cursor: "pointer" } }>
                        group members({ currentGroup?.memberCount || 0 })
                        <div className={ cls.rightContent }>
                            <Image className={ cls.icon } width={ 12 } height={ 12 } src={ rightOutLine }
                                   preview={ false }/>
                        </div>
                    </div>
                    <div className={ cls.memberList }>
                        <Space size={ 8 } wrap style={ { marginTop: 8 } }>
                            {
                                personList.map(item => {
                                    return <Tooltip title={ item.nickname } key={ item.userID }>
                                        <Avatar src={ replaceOssImage(item.faceURL) }
                                                size={ 28 }></Avatar>
                                    </Tooltip>;
                                })
                            }
                        </Space>
                    </div>
                </div>

                <div className={ cls.item }>
                    <div className={ cls.label } style={ { cursor: "pointer" } }>
                        group admin({ adminList.length || 0 })
                    </div>
                    <div className={ cls.memberList }>
                        <Space size={ 8 } wrap style={ { marginTop: 8 } }>
                            {
                                adminList.map(item => {
                                    return <Tooltip key={ item.userID } title={ item.nickname }>
                                        <Avatar src={ replaceOssImage(item.faceURL) }
                                                size={ 28 }></Avatar>
                                    </Tooltip>;
                                })
                            }
                        </Space>
                    </div>
                </div>
                {
                    currentGroup?.introduction.trim() && <div className={ cls.item }>
                        <div className={ cls.label }>Group description</div>
                        <div style={ { marginTop: 8 } }>{ currentGroup?.introduction }</div>
                    </div>
                }

                {
                    currentGroup?.notification.trim() && <div className={ cls.item }>
                        <div className={ cls.label }>Group announcement</div>
                        <div style={ { marginTop: 8 } }>{ currentGroup?.notification }</div>
                    </div>
                }

                {
                    [GroupMemberRole.Admin, GroupMemberRole.Owner].includes(selfInGroupInfo?.roleLevel || GroupMemberRole.Nomal) &&
                    <div className={ cls.item }>
                        <div className={ cls.label }>
                            Mute notifications
                            <div className={ cls.rightContent }>
                                <Switch onClick={ muteGroup } value={ currentGroup?.status }
                                        className={ cls.switch }/>
                            </div>
                        </div>
                    </div>
                }
                <div className={ cls.item }>
                    <Button danger block onClick={ clearHistory }>Clear chat history</Button>
                </div>

            </div>
        </Spin>

    </div>;
}
