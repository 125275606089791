import store2 from "store2";
import { TOKEN_TYPE } from "@/api/http";

export const clearIMProfile = () => {
    store2.remove("userID");
    store2.remove("chatToken");
    store2.remove("imToken");
    store2.remove("originToken");
};

export const getIMProfile = () => {
    const imUserID = store2.get("userID");
    const imToken = store2.get(TOKEN_TYPE.CHAT);
    return {
        imUserID,
        imToken
    }
};
