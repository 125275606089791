import { GetProps } from "antd";
import Icon from "@ant-design/icons";

function SvgIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M21.3081 10.9713C21.212 5.845 17.2381 1.7041 12.3583 1.7041H11.6417C6.76188 1.7041 2.78797 5.845 2.69193 10.9706C1.8348 11.4673 1.25 12.3839 1.25 13.4438V14.8772C1.25 16.4581 2.5357 17.7438 4.11667 17.7438C5.69763 17.7438 6.98333 16.4581 6.98333 14.8772V13.4438C6.98224 12.6888 6.6835 11.9647 6.15194 11.4286C5.62037 10.8925 4.89884 10.5876 4.1439 10.58C4.42412 6.42693 7.67563 3.13743 11.6417 3.13743H12.3583C16.3251 3.13743 19.5759 6.42693 19.8561 10.58C19.1012 10.5876 18.3796 10.8925 17.8481 11.4286C17.3165 11.9647 17.0178 12.6888 17.0167 13.4438V14.8772C17.0167 16.1715 17.8838 17.2551 19.0635 17.6098C17.9988 19.0112 16.4786 19.9971 14.7649 20.3976C14.6466 20.0428 14.4198 19.7341 14.1166 19.5151C13.8134 19.2961 13.449 19.1779 13.075 19.1772C12.5998 19.1772 12.1441 19.3659 11.8081 19.7019C11.4721 20.0379 11.2833 20.4936 11.2833 20.9688C11.2833 21.444 11.4721 21.8997 11.8081 22.2357C12.1441 22.5717 12.5998 22.7605 13.075 22.7605C13.3802 22.7601 13.6802 22.6814 13.9463 22.532C14.2125 22.3825 14.4358 22.1673 14.595 21.9069C15.8866 21.681 17.1133 21.176 18.1895 20.4271C19.2658 19.6782 20.1657 18.7036 20.8265 17.5711C21.943 17.1798 22.75 16.1263 22.75 14.8772V13.4438C22.75 12.3839 22.1652 11.4673 21.3081 10.9713ZM5.55 13.4438V14.8772C5.55 15.6676 4.90715 16.3105 4.11667 16.3105C3.32618 16.3105 2.68333 15.6676 2.68333 14.8772V13.4438C2.68333 12.6533 3.32618 12.0105 4.11667 12.0105C4.90715 12.0105 5.55 12.6533 5.55 13.4438ZM21.3167 14.8772C21.3167 15.6454 20.7075 16.2696 19.9478 16.304L19.8919 16.2775C19.8869 16.289 19.879 16.2983 19.874 16.3091C19.4957 16.3066 19.1338 16.1547 18.867 15.8865C18.6003 15.6182 18.4504 15.2554 18.45 14.8772V13.4438C18.45 12.6533 19.0928 12.0105 19.8833 12.0105C20.6738 12.0105 21.3167 12.6533 21.3167 13.4438V14.8772Z"
            fill="currentColor" stroke="currentColor" strokeWidth="0.5"/>
    </svg>;


}

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function ComponentIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ SvgIcon } { ...props }></Icon>;
}
