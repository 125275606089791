import { Button, Flex, Modal, Popover, Tooltip, message, Empty } from "antd";
import cls from "./tool.module.scss";
import { SettingOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { useAppDispatch } from "@/store";
import { updateInputText } from "@/store/slice/message.ts";
import { QrIcon, QuickIcon } from "@/components/icon";
import {
    ReplyType,
    getQuickList,
    updateQuickList,
} from "@/api/im";

import DynmicList from "./DynmicList";
import useSendMessage from "@/hooks/useSendMessage";
import { useParams } from "react-router-dom";
import { createImageMessage, createTextMessage } from "@chat/zim-sdk";

function Quick({ type = "quickReply" }: { type: "UPI" | "quickReply" }) {
    const dispatch = useAppDispatch();
    const { sendMessage } = useSendMessage();
    const { conversationID = "" } = useParams();
    const [list, setList] = useState<(ReplyType)[]>([]);
    const [visible, setVisible] = useState(false);

    const sendUPI = async (item: ReplyType) => {
        try {
            const message = createTextMessage(item.content || "");
            const imageMessage = await createImageMessage({
                fileDownloadUrl: item.otherUrl!,
                largeImageDownloadUrl: item.otherUrl!,
                thumbnailDownloadUrl: item.otherUrl!,
            });
            await sendMessage({ message, conversationID });
            await sendMessage({ message: imageMessage, conversationID });
        } catch (e) {
            message.error(`Send failed.${ e }`);
        }
    };
    const getList = async (type: 1 | 2, callback?: (list: ReplyType[]) => void) => {
        const res = await getQuickList(type);
        if (res.code === 0) {
            setList(res.data.content.sort((a, b) => a.sort! - b.sort!));
            callback?.(res.data.content);
        }
    };
    const getQuickReplys = (callback?: (list: ReplyType[]) => void) => {
        getList(1, callback);
    };
    const getUPIs = (callback?: (list: ReplyType[]) => void) => {
        getList(2, callback);
    };

    const onOk = () => {
        updateQuickList(list).then(() => {
            setVisible(false);
        });
    };

    useEffect(() => {
        getList(type === "quickReply" ? 1 : 2);
    }, [type]);
    return (
        <>
            <Popover
                content={
                    <div className={ cls.quickMessage }>
                        <Flex
                            justify="space-between"
                            className={ cls.quickMessage__title }
                            style={ { marginBottom: 7 } }
                        >
                            <div>{ type === "UPI" ? "UPI" : "Common Phrases" }</div>
                            <Button
                                onClick={ () => setVisible(true) }
                                icon={ <SettingOutlined/> }
                                size="small"
                            >
                                Set
                            </Button>
                        </Flex>
                        <ul className={ cls.quickMessage__list } style={ { maxHeight: 230 } }>
                            {
                                list.length ? list.map((item) => {
                                    return (
                                        <Fragment key={ item.id }>
                                            { type === "UPI" ? (
                                                <li key={ item.id } className={ cls.UPI__item }>
                                                    <Tooltip title={ item.content }>
                                                        <div className={ cls.UPI__item__text }>
                                                            { item.content }
                                                        </div>
                                                    </Tooltip>
                                                    <Button size="small" onClick={ () => sendUPI(item) }>
                                                        Send
                                                    </Button>
                                                </li>
                                            ) : (
                                                <li
                                                    className={ cls.quickMessage__item }
                                                    key={ item.id }
                                                    onClick={ () => {
                                                        dispatch(updateInputText(item.content));
                                                    } }
                                                >
                                                    <div className={ cls.quickMessage__item__text }>
                                                        { item.content }
                                                    </div>
                                                </li>
                                            ) }
                                        </Fragment>
                                    );
                                }) : <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE }/>
                            }
                        </ul>
                    </div>
                }
            >
                <>
                    { type === "UPI" ? (
                        <QrIcon className="tools-item"/>
                    ) : (
                        <QuickIcon className="tools-item"/>
                    ) }
                </>

            </Popover>
            <Modal
                width={ 682 }
                closable={ false }
                onCancel={ () => setVisible(false) }
                title={ type === "UPI" ? "Set UPI" : "Set common Phrases" }
                open={ visible }
                okText="Save"
                onOk={ onOk }
                centered
            >
                <DynmicList
                    list={ list }
                    onChangeList={ setList }
                    getQuickReplys={ getQuickReplys }
                    getUPIs={ getUPIs }
                    type={ type }
                />
            </Modal>
        </>
    );
}

export default Quick;
