import TextMessage from "@/pages/Message/feature/Conversation/MessageItem/TextMessage";
import { Avatar, Col, Dropdown, Image, MenuProps, Row } from "antd";
import { deleteMessages, useMessageItemClass, ZIMMessage } from "@chat/zim-sdk";
import { useAppDispatch } from "@/store";
import classname from "classnames";
import PictureMessage from "@/pages/Message/feature/Conversation/MessageItem/PictureMessage";
import QuoteMessage from "@/pages/Message/feature/Conversation/MessageItem/QuoteMessage";
import replyIcon from "@/assets/svg/reply.svg";
// import deleteIcon from "@/assets/svg/delete.svg";
import sendErrorIcon from "@/assets/svg/send-error.svg";
import sendingIcon from "@/assets/svg/send-sending.png";
import { removeMessage, setQuoteMessage } from "@/store/slice/message.ts";
import VideoMessage from "@/pages/Message/feature/Conversation/MessageItem/VideoMessage.tsx";
import VoiceMessage from "@/pages/Message/feature/Conversation/MessageItem/VoiceMessage.tsx";
import useSendMessage from "@/hooks/useSendMessage.ts";
import CardMessage from "@/pages/Message/feature/Conversation/MessageItem/cardMessage.tsx";
import {
    ChatMessageItem,
    CustomType,
    MessageStatus,
    MessageType,
    ZIMAudioMessage,
    ZIMCustomMessage,
    ZIMImageMessage,
    ZIMVideoMessage
} from "@chat/zim-sdk";
import { useUserInfo } from "@/hooks/useUserInfo.ts";

type MessageItemProps = {
    data: ChatMessageItem;
    isSelf: boolean
}

export default function MessageItem(props: MessageItemProps) {
    const { data, isSelf } = props;
    const dispatch = useAppDispatch();
    const { userInfo } = useUserInfo(data.senderUserID);
    const items: MenuProps['items'] = [
        {
            label: <Row gutter={ 8 } onClick={ () => {
                dispatch(setQuoteMessage(data));
            } }>
                <Col>
                    <Image preview={ false } src={ replyIcon } width={ 16 } height={ 16 }></Image>
                </Col>
                <Col flex={ 1 }>
                    Reply
                </Col>
            </Row>,
            key: '1',
        },
        // {
        //     label: <Row gutter={ 8 }>
        //         <Col>
        //             <Image preview={ false } src={ deleteIcon } width={ 16 } height={ 16 }></Image>
        //         </Col>
        //         <Col style={ { color: "rgba(223, 0, 12, 1)" } }>
        //             Delete message
        //         </Col>
        //     </Row>,
        //     key: '2',
        // },
    ];
    const getComponent = () => {
        switch (data.type) {
            case MessageType.Text:
                if (data.repliedInfo) {
                    return <QuoteMessage data={ data }></QuoteMessage>;
                }
                return <TextMessage data={ data }/>;
            case MessageType.Image:
                return <PictureMessage data={ data as ZIMImageMessage }></PictureMessage>;
            case MessageType.Video:
                return <VideoMessage data={ data as ZIMVideoMessage }/>;
            case MessageType.Audio:
                return <VoiceMessage data={ data as ZIMAudioMessage }/>;
            case MessageType.Custom:
                const customMessage = data as ZIMCustomMessage;
                if (customMessage.subType === CustomType.Card) {
                    return <CardMessage data={ customMessage }/>;
                }
                return data.type + "-" + customMessage.subType;
            default:
                return data.type;
        }
    };
    const { sendMessage } = useSendMessage();
    const repeatSend = async (message: ZIMMessage) => {
        dispatch(removeMessage(message.messageID));
        await deleteMessages([message], message.conversationID, false);
        const newMessage = JSON.parse(JSON.stringify(message))
        delete newMessage.localMessageID;
        delete newMessage.messageID;
        await sendMessage({ message: newMessage, conversationID: data.conversationID });
    };
    const renderStatus = () => {
        switch (data.sentStatus) {
            case MessageStatus.Failed:
                return <div className="message-item-status"
                            onClick={ () => repeatSend(data) }>
                    <Image src={ sendErrorIcon } preview={ false } className="message-item-status__error"/>
                </div>;
            case MessageStatus.Sending:
                return <div className="message-item-status">
                    <Image className="message-item-status__sending" preview={ false } src={ sendingIcon }></Image>
                </div>;
            default:
                return null;
        }
    };
    const [layoutClassname] = useMessageItemClass(props.data);
    return <div className={ classname("message-layout", isSelf && "message-layout__self") }>
        <div className="message-item-icon">
            <Avatar size={ 28 } src={ userInfo?.userAvatarUrl }></Avatar>
        </div>
        <Dropdown overlayClassName="contextMenu" trigger={ ["contextMenu"] } menu={ { items } }>

            <div className={ classname("message-item-content", layoutClassname) }>
                {
                    !isSelf && <div className="nickname">{ userInfo?.userName }</div>
                }
                {
                    getComponent()
                }
            </div>
        </Dropdown>
        {
            renderStatus()
        }
    </div>;


}
