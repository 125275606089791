import Icon from "@ant-design/icons";
import { GetProps } from "antd";

const PasswordSvg = () =>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 8.08325C3.01117 8.08325 2.75 8.34442 2.75 8.66659V13.3333C2.75 13.6554 3.01117 13.9166 3.33333 13.9166H12.6667C12.9888 13.9166 13.25 13.6554 13.25 13.3333V8.66659C13.25 8.34442 12.9888 8.08325 12.6667 8.08325H3.33333ZM1.25 8.66659C1.25 7.51599 2.18274 6.58325 3.33333 6.58325H12.6667C13.8173 6.58325 14.75 7.51599 14.75 8.66659V13.3333C14.75 14.4838 13.8173 15.4166 12.6667 15.4166H3.33333C2.18274 15.4166 1.25 14.4838 1.25 13.3333V8.66659Z" fill="#848FA0"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.00033 2.08325C7.31518 2.08325 6.6581 2.35542 6.17363 2.83989C5.68916 3.32436 5.41699 3.98144 5.41699 4.66659V7.33325C5.41699 7.74747 5.08121 8.08325 4.66699 8.08325C4.25278 8.08325 3.91699 7.74747 3.91699 7.33325V4.66659C3.91699 3.58362 4.3472 2.54501 5.11297 1.77923C5.87875 1.01346 6.91736 0.583252 8.00033 0.583252C9.08329 0.583252 10.1219 1.01346 10.8877 1.77923C11.6535 2.54501 12.0837 3.58362 12.0837 4.66659V7.33325C12.0837 7.74747 11.7479 8.08325 11.3337 8.08325C10.9194 8.08325 10.5837 7.74747 10.5837 7.33325V4.66659C10.5837 3.98144 10.3115 3.32436 9.82702 2.83989C9.34255 2.35542 8.68547 2.08325 8.00033 2.08325Z" fill="#848FA0"/>
    </svg>

type CustomIconComponentProps = GetProps<typeof Icon>;

export default function PasswordIcon(props: Partial<CustomIconComponentProps>) {
    return <Icon component={ PasswordSvg } { ...props }></Icon>;
}
